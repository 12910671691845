import { Button, Drawer } from "antd";
import React from "react";
import { useContext } from "react";
import PricingUI from ".";
import { AuthContext } from "../../Auth";
import { useGetModalValues, useSetModalValues } from "../../redux/hooks/modals";
import LockIcon from "../../components/Icons/LockIcon";
import { CompleteDrawer } from "../../components/RequestBanner/ParaplannerBanner";

const PricingDrawer = () => {
  const { open, soaId } = useGetModalValues("pricingDrawer");
  const setModalValues = useSetModalValues();
  const onDrawerClose = () => {
    setModalValues("pricingDrawer", {
      open: false,
      soaId: null,
    });
  };

  const {
    parsedToken: {
      realm_access: { roles },
    },
  } = useContext(AuthContext);

  const isParaplannerLead = roles && roles.includes("paraplanner-lead");

  const onLockClick = () => {
    onDrawerClose();
    setModalValues("completeDrawer", {
      open: true,
    });
  };

  return (
    <>
      <Drawer
        title={
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {isParaplannerLead && (
              <Button
                size="sm"
                className="pricing-lock"
                style={{ marginRight: "10px", marginTop: "2px" }}
                type="text"
                onClick={onLockClick}
                icon={<LockIcon />}
              />
            )}
            Pricing
          </div>
        }
        width="50%"
        placement="right"
        onClose={onDrawerClose}
        closable={false}
        visible={open}
        style={{
          zIndex: 999999999,
        }}
        footer={null}
        className="pricing-drawer"
        forceRender
      >
        <PricingUI soaId={soaId} readOnly showSubmit={false} />
      </Drawer>
      <CompleteDrawer isPricingUI />
    </>
  );
};

export default PricingDrawer;
