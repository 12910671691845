import { createReducer } from "reduxsauce";
import { Types } from "../../actions/ancillary";

// the initial state of this reducer
export const INITIAL_STATE = {
  projectionRequirements: null,
};

export const setProjectionRequirements = (
  state = INITIAL_STATE,
  { payload }
) => {
  return {
    ...state,
    projectionRequirements: payload,
  };
};

export const HANDLERS = {
  [Types.SET_PROJECTION_REQUIREMENTS]: setProjectionRequirements,
};

export default createReducer(INITIAL_STATE, HANDLERS);
