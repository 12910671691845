import { useDispatch, useSelector } from "react-redux";
import { Creators } from "../../actions/basicDetails";
import {
  getServiceOffering,
  getServiceOfferingAddOns,
  getClient,
  getPartner,
  getUiOptions,
  getPracticeServices,
  getSmsf,
  getTrust,
  getCompany,
} from "../../selectors/basicDetails";

export const useUpdateServiceOffering = () => {
  const dispatch = useDispatch();

  return serviceOffering => {
    dispatch(
      Creators.updateServiceOffering({
        serviceOffering,
      })
    );
  };
};

export const useUpdateClientPartner = () => {
  const dispatch = useDispatch();

  return ({ client, partner }) => {
    dispatch(
      Creators.updateClientPartner({
        client,
        partner,
      })
    );
  };
};

export const useServiceOffering = () => {
  return useSelector(getServiceOffering);
};

export const useServiceOfferingAddOns = () => {
  return useSelector(getServiceOfferingAddOns);
};

export const useClientAndPartner = () => {
  const client = useSelector(getClient);
  const partner = useSelector(getPartner);
  return { client, partner };
};

export const useSmsf = () => {
  return useSelector(getSmsf);
};

export const useTrust = () => {
  return useSelector(getTrust);
};

export const useCompany = () => {
  return useSelector(getCompany);
};

export const useUiOptions = () => {
  return useSelector(getUiOptions);
};

export const useUpdateUiOptions = () => {
  const dispatch = useDispatch();

  return uiOptions => {
    dispatch(
      Creators.updateUiOptions({
        uiOptions,
      })
    );
  };
};

export const usePracticeServices = () => {
  return useSelector(getPracticeServices);
};

export const useUpdatePracticeServices = () => {
  const dispatch = useDispatch();

  return practiceServices => {
    dispatch(
      Creators.updatePracticeServices({
        practiceServices,
      })
    );
  };
};
