import { isEqual } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Creators } from "../../actions/recommendedStrategies";
import {
  getAutoCreateData,
  getFsoaStrategies,
  getAllStrategies,
  getStrategy,
  getAlerts,
} from "../../selectors/recommendedStrategies";

export const useSetAutoCreateData = () => {
  const dispatch = useDispatch();

  return payload => {
    dispatch(
      Creators.setAutoCreate({
        payload,
      })
    );
  };
};

export const useGetAutoCreateData = () => {
  const values = useSelector(getAutoCreateData);
  return values;
};

export const useSetFsoaStrategies = () => {
  const dispatch = useDispatch();

  return payload => {
    dispatch(
      Creators.setFsoaStrategies({
        payload,
      })
    );
  };
};

export const useGetFsoaStrategies = () => {
  const values = useSelector(getFsoaStrategies);
  return values;
};

export const useSetAlerts = () => {
  const dispatch = useDispatch();

  return payload => {
    dispatch(
      Creators.setAlerts({
        payload,
      })
    );
  };
};

export const useGetAlerts = () => {
  const values = useSelector(getAlerts);
  return values;
};

export const useAllStrategies = () => {
  const dispatch = useDispatch();
  const allStrategies = useSelector(getAllStrategies);

  const updateAllStrategies = payload => {
    if (!isEqual(allStrategies, payload)) {
      dispatch(
        Creators.updateAllStrategies({
          payload,
        })
      );
    }
  };

  const addStrategy = ({
    strategyKey,
    key,
    dropdownValue,
    owner,
    parameters,
  }) => {
    dispatch(
      Creators.addStrategy({
        strategyKey,
        key,
        dropdownValue,
        owner,
        parameters,
      })
    );
  };

  const deleteStrategy = id => {
    dispatch(
      Creators.deleteStrategy({
        id,
      })
    );
  };

  const editStrategyParameters = ({ id, key, value }) => {
    dispatch(
      Creators.editStrategyParameters({
        id,
        key,
        value,
      })
    );
  };

  const reorderStrategies = ({ startIndex, endIndex }) => {
    dispatch(
      Creators.reorderStrategies({
        startIndex,
        endIndex,
      })
    );
  };

  return {
    allStrategies,
    updateAllStrategies,
    addStrategy,
    deleteStrategy,
    editStrategyParameters,
    reorderStrategies,
  };
};

export const useStrategy = id => {
  const dispatch = useDispatch();
  const strategy = useSelector(getStrategy(id));

  const editStrategy = ({ key, value }) => {
    dispatch(
      Creators.editStrategy({
        id,
        key,
        value,
      })
    );
  };

  const editStrategyMetadata = ({ key, value }) => {
    dispatch(
      Creators.editStrategyMetadata({
        id,
        key,
        value,
      })
    );
  };

  const duplicateStrategy = () => {
    dispatch(
      Creators.duplicateStrategy({
        id,
      })
    );
  };

  return { strategy, editStrategy, editStrategyMetadata, duplicateStrategy };
};

export const useClearXplanDropdowns = () => {
  const dispatch = useDispatch();

  const clearXplanDropdowns = () => {
    dispatch(Creators.clearXplanDropdowns());
  };

  return { clearXplanDropdowns };
};
