import styled from "styled-components";

export const LoadingBackground = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0px;
  left: 0px;
  background: #f1f6fa;
`;

export const ErrorMsg = styled.span`
  color: red;
`;
