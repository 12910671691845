import { notification } from "antd";
import axios from "axios";
// import https from "https";
import { get } from "lodash";
import appInsights from "./applicationInsights";
import keycloak from "./keycloakAuth";

export function getAPIErrorMessage(
  error,
  defaultMessage = "Internal server error"
) {
  return get(error, "dataSource.error", defaultMessage);
}

const createRequestInterceptor = axiosInstance => {
  axiosInstance.interceptors.request.use(async extendedConfig => {
    const config = Object.assign({}, extendedConfig);

    const accessToken = (await ApiUtils.getAccessToken()) || null;

    if (!config.headers.Authorization) {
      config.headers.Authorization = accessToken && `Bearer ${accessToken}`;
    }

    return config;
  });
};

const createResponseInterceptor = axiosInstance => {
  axiosInstance.interceptors.response.use(
    response => response,
    async error => {
      console.error(error);
      if (error && error.response && error.response.status === 401) {
        const accessToken = await ApiUtils.getAccessToken();
        error.config.headers["Authorization"] = `Bearer ${accessToken}`;
        return axiosInstance.request(error.config);
      } else if (error && error.response && error.response.status === 403) {
        notification.error({
          message: "Access Denied",
          description:
            "You are not authorized to view this request, please request access from the owning party.",
          key: "access-denied-notification",
          duration: 0,
        });
      }
      return Promise.reject(error);
    }
  );
};

const blockRequestInterceptor = axiosInstance => {
  axiosInstance.interceptors.request.use(config => {
    // Block all requests
    return {
      headers: {},
      method: config.method,
      url: "",
    };
  });
};

const getBaseUrl = () => {
  return `https://${process.env.REACT_APP_BASE_HOST}/api`;
};
class ApiUtils {
  // TODO: CHANGES REQUIRED

  static BASE_URL = getBaseUrl();
  static DSRF_BASE_URL = "/dsrf";
  static CLIENT_BASE_URL = "/client";
  static DOCUMENET_BASE_URL = "/document";
  static INVESTMENT_BASE_URL = "/investment";

  static HTTPS = axios.create({
    withCredentials: true,
    // httpsAgent: new https.Agent({
    //   rejectUnauthorized: false,
    // }),
    baseURL: ApiUtils.BASE_URL,
  });

  static DSRF_HTTPS = axios.create({
    withCredentials: true,
    // httpsAgent: new https.Agent({
    //   rejectUnauthorized: false,
    // }),
    baseURL: ApiUtils.BASE_URL + ApiUtils.DSRF_BASE_URL,
    // baseURL:"http://localhost:7071/api"
  });

  static CLIENT_HTTPS = axios.create({
    withCredentials: true,
    // httpsAgent: new https.Agent({
    //   rejectUnauthorized: false,
    // }),
    baseURL: ApiUtils.BASE_URL + ApiUtils.CLIENT_BASE_URL,
  });

  static DOCUMENT_HTTPS = axios.create({
    withCredentials: true,
    // httpsAgent: new https.Agent({
    //   rejectUnauthorized: false,
    // }),
    baseURL: ApiUtils.BASE_URL + ApiUtils.DOCUMENET_BASE_URL,
  });

  static INVESTMENT_HTTPS = axios.create({
    withCredentials: true,
    // httpsAgent: new https.Agent({
    //   rejectUnauthorized: false,
    // }),
    baseURL: ApiUtils.BASE_URL + ApiUtils.INVESTMENT_BASE_URL,
  });

  static async getAccessToken() {
    let accessToken = JSON.parse(
      window.sessionStorage.getItem("__keycloak_token__")
    );
    try {
      const refreshed = await keycloak.updateToken(60);

      if (refreshed) {
        accessToken = keycloak.token;
        window.sessionStorage.setItem(
          "__keycloak_token__",
          JSON.stringify(keycloak.token)
        );
        window.sessionStorage.setItem(
          "__keycloak_token_parsed__",
          JSON.stringify(keycloak.tokenParsed)
        );
      }

      return accessToken;
    } catch (error) {
      console.error(error);
      ApiUtils.handleLogout();
    }
  }

  static handleLogout() {
    sessionStorage.clear();
    appInsights.clearAuthenticatedUserContext();
    return keycloak.logout();
  }
}

// Root
createRequestInterceptor(ApiUtils.HTTPS);
createResponseInterceptor(ApiUtils.HTTPS);

// DSRF
createRequestInterceptor(ApiUtils.DSRF_HTTPS);
createResponseInterceptor(ApiUtils.DSRF_HTTPS);

// CLIENT (Paraplanner)
blockRequestInterceptor(ApiUtils.CLIENT_HTTPS);
// createRequestInterceptor(ApiUtils.CLIENT_HTTPS);
createResponseInterceptor(ApiUtils.CLIENT_HTTPS);

// Documents (SoA Generation)
createRequestInterceptor(ApiUtils.DOCUMENT_HTTPS);
createResponseInterceptor(ApiUtils.DOCUMENT_HTTPS);

// Current Investment
blockRequestInterceptor(ApiUtils.INVESTMENT_HTTPS);
// createRequestInterceptor(ApiUtils.INVESTMENT_HTTPS);
createResponseInterceptor(ApiUtils.INVESTMENT_HTTPS);

export default ApiUtils;
