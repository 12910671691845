import { createActions } from "reduxsauce";

import { REDUX_KEY } from "../../../utils/constants";

export const { Types, Creators } = createActions(
  {
    setProjectionRequirements: {
      payload: {},
    },
  },
  { prefix: `${REDUX_KEY.ANCILLARY}_` }
);
