import ApiUtils from "../../utils/apiUtils";
import { getSoaId } from "../../utils/common";
import { WAIT_BEFORE_SUBMIT } from "../../utils/constants";

const startForm = async () => {
  const { data } = await ApiUtils.DSRF_HTTPS.post("/soa");
  return data;
};

const startRoaForm = async () => {
  const { data } = await ApiUtils.DSRF_HTTPS.post(
    "/soa?serviceOffering=review"
  );
  return data;
};

const getSoaById = async ({ queryKey }) => {
  const { soaId } = queryKey[1];
  const { data } = await ApiUtils.DSRF_HTTPS.get(`/soa/${soaId}`);
  return data;
};

const getAllSoa = async ({ queryKey }) => {
  const key = queryKey[0];

  const { data } = await ApiUtils.DSRF_HTTPS.get(`${key}`);
  return data;
};

const getAllSoaV2 = async ({ page, limit, serviceOffering, status, name, advisorid }) => {
  const { data } = await ApiUtils.DSRF_HTTPS.get
    (`/v2/soa`,{
      params:{
        limit,
        page,
        serviceOffering,
        status,
        name,
        with_total:false,
        advisor_id:advisorid == "all" ? null : advisorid
      }
    });
  return data;
};

const getTotal = async ({ page, limit, serviceOffering, status, name }) => {
  const { data } = await ApiUtils.DSRF_HTTPS.get
    (`/v2/soa?limit=${limit}&page=${page}&serviceOffering=${serviceOffering}&status=${status}&name=${name}&with_total=${true}`);
  return data ? data.data.total : null;
};

const syncTabData = async ({
  tab,
  tableData,
  adviceId,
  action,
  childId,
  parentId,
}) => {
  const { data } = await ApiUtils.DSRF_HTTPS.put(
    `/soa/${adviceId}?section=${tab}${action ? `&action=${action}` : ""}${childId ? `&childId=${childId}` : ""
    }${parentId ? `&parentId=${parentId}` : ""}`,
    tableData
  );
  return data;
};

const submitInfo = async () => {
  const soaId = getSoaId();

  const submit = new Promise(resolve => {
    setTimeout(() => {
      resolve(ApiUtils.DSRF_HTTPS.put(`/soa/${soaId}/?action=submit`));
    }, WAIT_BEFORE_SUBMIT);
  });

  const { data } = await submit;
  return data;
};

const previewInfo = async () => {
  const soaId = getSoaId();

  const preview = new Promise(resolve => {
    setTimeout(() => {
      resolve(ApiUtils.DSRF_HTTPS.put(`/soa/${soaId}/?action=preview`));
    }, WAIT_BEFORE_SUBMIT);
  });

  const { data } = await preview;
  return data;
};

const getPriceData = async () => {
  const soaId = getSoaId();
  const { data } = await ApiUtils.DSRF_HTTPS.get(`/soa/${soaId}/pricing`);
  return data;
};

const submitFeedback = async payload => {
  const soaId = getSoaId();
  const { data } = await ApiUtils.DSRF_HTTPS.put(
    `/soa/${soaId}/?action=rating`,
    payload
  );
  return data;
};

export {
  startForm,
  startRoaForm,
  syncTabData,
  submitInfo,
  submitFeedback,
  getPriceData,
  getSoaById,
  getAllSoa,
  getAllSoaV2,
  getTotal,
  previewInfo,
};
