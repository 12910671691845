import { createActions } from "reduxsauce";

import { REDUX_KEY } from "../../../utils/constants";

export const { Types, Creators } = createActions(
  {
    addProduct: {
      id: null,
      targetProduct: "",
      owner: "",
      recomendationType: "",
      portfolioType: {
        selected: "",
      },
    },
    editProductAtIndex: {
      index: -1,
      id: null,
      targetProduct: "",
      owner: "",
      recomendationType: "",
      portfolioType: {
        selected: "",
      },
    },
    deleteProduct: {
      index: -1,
    },
    editProduct: {
      index: -1,
      values: {},
    },
    editPlatformToConsider: {
      index: -1,
      platformToConsiderIdx: -1,
      value: "",
    },
    hydrate: {
      products: [],
    },
    addPlatformToConsider: null,
  },
  { prefix: `${REDUX_KEY.INVESTMENT_RESEARCH}_` }
);
