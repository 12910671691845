import { createActions } from "reduxsauce";

import { REDUX_KEY } from "../../../utils/constants";

export const { Types, Creators } = createActions(
  {
    addPlatform: {
      id: null,
      targetProduct: "",
      owner: "",
      recomendationType: "",
      portfolioType: {
        selected: "",
      },
      replacement: { requirement: "" },
    },
    editPlatformAtIndex: {
      index: -1,
      id: null,
      targetProduct: "",
      owner: "",
      recomendationType: "",
      portfolioType: {
        selected: "",
      },
      replacement: { requirement: "" },
    },
    deletePlatform: {
      index: -1,
    },
    editPlatform: {
      index: -1,
      values: {},
    },
    hydrate: {
      platforms: [],
    },
    setInvestmentProducts: {
      products: {},
    },
  },
  { prefix: `${REDUX_KEY.INVESTMENT}_` }
);
