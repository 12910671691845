import { createActions } from "reduxsauce";

import { REDUX_KEY } from "../../../utils/constants";

export const { Types, Creators } = createActions(
  {
    updateBasicDetails: {
      soaRequestDetails: {
        appointmentDate: null,
        serviceOffering: "",
        serviceOfferingAddOns: [],
        updateFactFindWizard: false,
        serviceRequestWriting: false,
        complianceChecklist: false,
      },
      client: {
        salutation: undefined,
        firstName: undefined,
        lastName: undefined,
      },
      partner: {
        salutation: undefined,
        firstName: undefined,
        lastName: undefined,
      },
      smsf: null,
      trust: null,
      company: null,
    },
    updateClientPartner: {
      client: {
        firstName: undefined,
        lastName: undefined,
      },
      partner: {
        firstName: undefined,
        lastName: undefined,
      },
    },
    updateServiceOffering: {
      serviceOffering: "",
    },
    updateUiOptions: {
      uiOptions: {},
    },
    updatePracticeServices: {
      practiceServices: {},
    },
  },
  { prefix: `${REDUX_KEY.BASIC_DETAILS}_` }
);
