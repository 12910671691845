import React, { useEffect, useState, useRef, useContext, useMemo } from "react";
import {
  Comment,
  Avatar,
  Row,
  message,
  Modal,
  Dropdown,
  Menu,
  Button,
  Popconfirm,
  Badge,
  Divider,
} from "antd";
import {
  LoadingOutlined,
  EyeOutlined,
  DownOutlined,
  DeleteOutlined,
  WarningOutlined,
  FileOutlined,
  DownloadOutlined,
  CommentOutlined,
} from "@ant-design/icons";
import { AuthContext } from "../../Auth";
import moment from "moment";
import { getFileAttachment, useAcknowledgeComments, useAcknowledgeSingleComment, useCommentsData } from "../../api/currentPosition/comments";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { checkIsImage, getFileIcon, getFileType } from "./utils";

import { useGetStatus } from "../../redux/hooks/request";
import {
  COMMENT,
  COMMENT_PREVIEW_MAX_LENGTH,
  REQUEST_STATUS,
} from "../../utils/constants";
import { useGetModalValues } from "../../redux/hooks/modals";
import { downloadFile } from "../../api/paraplanner/soaGeneration";

import "./index.css";
import AddComment from "./AddComment";
import ReplyIcon from "../../components/Icons/ReplyIcon";
import { useQueries } from "react-query";
import { tabEntityTypeTitles } from "../../components/WithComments/constants";

const ReplyToPreview = ({ replyToId }) => {
  const { summary } = useGetModalValues("commentsDrawer");
  const entries= useCommentsData()
  const comments = useMemo(
    () => {
      const cs = [];
      for (const e of entries) {
        for (const t of e.threads) {
          cs.push(...t.comments)
        }
      }
      return cs;
    },
    [entries]
  );

  const {
    parsedToken: {
      sub,
      realm_access: { roles },
    },
  } = useContext(AuthContext);

  const { stakeholders } = useGetModalValues("commentsDrawer");

  if (!replyToId) {
    return null;
  }

  const comment = comments?.find(c => c.commentId === replyToId);

  const isParaplanner = roles && roles.includes("paraplanner");

  const isOwner = sub === comment?.owner?.id;

  const alignRight = shouldAlignRight({
    stakeholders,
    ownerId: comment?.owner?.id,
    userIsOwner: isOwner,
    userIsParaplanner: isParaplanner,
  });

  const showName = isParaplanner || alignRight;

  const name = showName ? comment?.owner?.name : "PL Team";

  const onPreviewClick = () => {
    const comment = document.getElementById(`comment-${replyToId}`);
    if (comment) {
      comment.scrollIntoView(false);
      comment.classList.add("pulse-animate");
      setTimeout(() => {
        comment.classList.remove("pulse-animate");
      }, 2000);
    }
  };
  if (!comment) {
    return null;
  }

  return (
    <div className="reply-to-preview" onClick={onPreviewClick}>
      <span className="author">{name}</span>
      <span className="content">
        {comment.attachments.length === 0 ? (
          comment.content.length > COMMENT_PREVIEW_MAX_LENGTH ? (
            `${comment.content.slice(0, COMMENT_PREVIEW_MAX_LENGTH)}...`
          ) : (
            comment.content
          )
        ) : (
          <>
            <FileOutlined /> {comment.attachments.length} Attachment
            {comment.attachments.length === 1 ? "" : "s"}
          </>
        )}
      </span>
    </div>
  );
};

const FileAttachment = ({
  comment,
  attachment,
  handlePreviewFile,
  handleDeleteFile,
  handleDeleteComment,
}) => {
  const {
    parsedToken: {
      sub,
      realm_access: { roles },
    },
  } = useContext(AuthContext);

  const isParaplanner = roles && roles.includes("paraplanner");

  const isOwner = sub === comment?.owner?.id;

  const onDeleteConfirm = () => {
    if (comment.attachments.length === 1 && !comment.content) {
      handleDeleteComment(comment.commentId);
    } else {
      handleDeleteFile({
        commentId: comment.commentId,
        attachementId: attachment.id,
      });
    }
  };

  const isImage = checkIsImage(attachment.extension);

  const [url, setUrl] = useState(null);

  const fetchUrl = async () => {
    const url = await handlePreviewFile({
      commentId: comment.commentId,
      attachementId: attachment.id,
      fileTitle: attachment.fileName || attachment.name,
      extension: attachment.extension,
      showModal: false,
    });

    setUrl(url);
  };

  useEffect(() => {
    if (isImage) {
      fetchUrl();
    }
  }, [isImage]);

  const handleDownloadFile = async () => {
    let url = await handlePreviewFile({
      commentId: comment.commentId,
      attachementId: attachment.id,
      fileTitle: attachment.fileName || attachment.name,
      extension: attachment.extension,
      showModal: false,
    });

    const fileName = attachment.fileName;

    downloadFile(url, fileName, true);
  };

  return (
    <div className={`file-attachment ${isImage ? "is-image" : ""}`}>
      {comment.loading ? (
        <>
          <LoadingOutlined />
          <p>Uploading...</p>
        </>
      ) : (
        <>
          <div className={`file-preview`}>
            <EyeOutlined
              title="Preview file"
              onClick={() => {
                handlePreviewFile({
                  commentId: comment.commentId,
                  attachementId: attachment.id,
                  fileTitle: attachment.fileName || attachment.name,
                  extension: attachment.extension,
                });
              }}
            />
            {isOwner && isParaplanner && (
              <Popconfirm
                overlayStyle={{ zIndex: 999999999999999 }}
                placement="bottomRight"
                title="Are you sure you want to delete this file?"
                onConfirm={onDeleteConfirm}
                arrowPointAtCenter
              >
                <DeleteOutlined title="Delete file" />
              </Popconfirm>
            )}
            <DownloadOutlined
              title="Download file"
              onClick={handleDownloadFile}
            />
          </div>
          {isImage ? (
            url ? (
              <>
                <img
                  className="preview-image"
                  src={url}
                  alt={attachment.fileName || attachment.name}
                />
              </>
            ) : (
              <LoadingOutlined />
            )
          ) : (
            <>
              {getFileIcon(attachment.extension)}
              <p>{attachment.fileName || attachment.name}</p>
            </>
          )}
        </>
      )}
    </div>
  );
};

export const shouldAlignRight = ({
  stakeholders,
  ownerId,
  userIsOwner,
  userIsParaplanner,
}) => {
  // In case stake holders havent loaded yet
  if (stakeholders.length === 0) {
    return userIsOwner;
  }

  // For current logged in users
  if (userIsOwner) {
    return true;
  }

  // For commenters when logged in user is paraplanner
  if (userIsParaplanner) {
    return (
      stakeholders.find(stakeholder => stakeholder.id === ownerId)?.type ===
      "paraplanner"
    );
  }

  // For commenters when logged in user is not paraplanner
  return (
    stakeholders.find(stakeholder => stakeholder.id === ownerId)?.type !==
    "paraplanner"
  );
};

const CommentsListItem = ({
  isBeingEdited,
  comment,
  handlePreviewFile,
  handleDeleteComment,
  handleDeleteFile,
  setEditCommentId,
  handleRequestConfirmation,
  handleRequestEditApproval,
  onReadComment,
  isAddCommentPending,
  setShowAddComment,
  handleEditComment
}) => {
  const {
    parsedToken: {
      sub,
      realm_access: { roles },
    },
  } = useContext(AuthContext);
  const commentRow = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          onReadComment(comment);
        }
      },
      { threshold: 0.3 }
    );

    if (commentRow.current) {
      observer.observe(commentRow.current);
    }

    return () => {
      if (commentRow.current) {
        observer.unobserve(commentRow.current);
      }
    };
  }, [comment]);




  const { stakeholders } = useGetModalValues("commentsDrawer");

  const status = useGetStatus();
  const isParaplanner = roles && roles.includes("paraplanner");

  const isOwner = sub === comment?.owner?.id;

  const requestEditedNotOwner =
    !isOwner &&
    comment?.requestEdited &&
    typeof comment.editApproved !== "boolean";

  const requestEdited =
    comment?.requestEdited && typeof comment.editApproved !== "boolean";

  // const requestEditApproved = comment.editApproved;

  const alignRight = shouldAlignRight({
    stakeholders,
    ownerId: comment?.owner?.id,
    userIsOwner: isOwner,
    userIsParaplanner: isParaplanner,
  });

  // const showName = isParaplanner || alignRight;
  let showName = ''
  // const name = showName ? comment?.owner?.name : "PL Team";

  // const [ownerFirstName, ownerLastName] = name.split(" ");

  // const initials = showName
  //   ? `${ownerFirstName ? ownerFirstName[0] : ""}` +
  //   `${ownerLastName ? ownerLastName[0] : ""}`
  //   : "PL";

    function namechange(commentOwnerName) {
      const [ownerFirstName, ownerLastName] = commentOwnerName.split(" ");
      const initials = showName
        ? `${ownerFirstName ? ownerFirstName[0] : ""}` +
          `${ownerLastName ? ownerLastName[0] : ""}`
        : "PL"; 
      return initials;
    }

  function getCommentComponentData(singleComment) {
    
    if(!singleComment) return null;
    showName = isParaplanner ||singleComment.owner.teamName == 'Advisor'
    const commentOwnerName = showName ? singleComment?.owner?.name : "PL Team"
    const avaSrc = singleComment?.owner?.avatarUrl ? singleComment?.owner?.avatarUrl:null
    const isRequestEdited =  singleComment?.requestEdited && typeof singleComment.editApproved !== "boolean";
    const requestEditApproved = singleComment.editApproved;
    return {
      id: singleComment.commentId,
      author: <>{commentOwnerName}</>,
      avatar: (
        <Avatar
          src={null}
          alt={commentOwnerName}
          style={{
            backgroundColor :singleComment.owner.teamName == 'Advisor' ?"#7765CF":"#878D96",
          }}
        >
          {namechange(commentOwnerName)}
        </Avatar>
      ),
      content: (
        <>
          <ReplyToPreview replyToId={singleComment.replyToId} />
          {singleComment.attachments?.length === 0 ? (
            <p style={{ marginBottom:!singleComment.read? 5:0}}>{singleComment.content}</p>
          ) : (
            <>
              <div>
                <div>
                  {singleComment.content ?
                    <div className="">
                      <p>{singleComment.content}&lrm;</p>
                    </div>
                    : ""
                  }
                  <div className="file-attachment-container container-width">
                    {singleComment.attachments.map(attachment => {
                      return (
                        <FileAttachment
                          key={attachment.id}
                          comment={singleComment}
                          attachment={attachment}
                          handlePreviewFile={handlePreviewFile}
                          handleDeleteFile={handleDeleteFile}
                          handleDeleteComment={handleDeleteComment}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
              {singleComment.loading && (
                <span className="file-uploading">
                  <span className="file-uploading__element" />
                </span>
              )}
            </>
          )}
        </>
      ),
      attachments: singleComment.attachments,
      datetime: (
        <>
          {moment.parseZone(singleComment.createdOn).fromNow(true)} ago{" "}
          {typeof requestEditApproved === "boolean" &&
            (requestEditApproved ? (
              <span className="request-edit-approved request-edit-approved-color">Approved</span>
            ) : (
              <span className="request-edit-rejected request-edit-rejected-color">Rejected</span>
            ))}
          {isRequestEdited && (
            <span className="request-confirmation request-confirmation-color">Confirmation Requested</span>
          )}
          {/* Reply Button Display */}
          {/* {replyButton}
          {replyInThreadButton} */}
        </>
      ),
    }
  }
    

  const showDropdown =
    isParaplanner &&
    isOwner &&
    [REQUEST_STATUS.inReview_reviewing, REQUEST_STATUS.processing].includes(
      status
    ) &&
    !comment.loading;

  // const showEdit = comment.attachments.length === 0;
  const showEdit = comment?.content?.length > 0;

  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [deleteCommentId,setDeleteCommentId] = useState(null)

  const onDeleteMenuClick = (commentId) => {
    setDeleteCommentId(commentId)
    setIsDeleteConfirmOpen(true);
  };

  const onDeleteConfirm = () => {
    handleDeleteComment(deleteCommentId);
    setIsDeleteConfirmOpen(false);
  };

  const onDeleteCancel = () => {
    setIsDeleteConfirmOpen(false);
    setDeleteCommentId(null)
  };

  // Handle Edit comments

  const [selectedEditThreadComment,setSelectedThreadEditComment] =useState(null)
  const onEditMenuClick = (editComment,isThread) => {
    if(isThread){
      setSelectedThreadEditComment(editComment)
      return
    }
    setEditCommentId(editComment);
    setShowAddComment(true)
  };

  // Use latter to reset the select thread comment
  function resetThreadEditComment(){
    setSelectedThreadEditComment(null)
  }

  const onRequestConfirmationMenuClick = (commentId) => {
    handleRequestConfirmation({ commentId});
  };

  const onRequestEditApprove = (commentId) => {
    handleRequestEditApproval({
      commentId,
      editApproved: true,
    });
    // CHECKME
    // setReplyToComment({ id: comment.commentId, type: "approve" });
  };

  const onRequestEditReject = (commentId) => {
    handleRequestEditApproval({
      commentId,
      editApproved: false,
    });
    // CHECKME
    // setReplyToComment({ id: comment.commentId, type: "reject" });
  };

  function actions(renderComment,isThread){
    return  <Menu subMenuCloseDelay={0}>
    <Menu.Item onClick={()=>onRequestConfirmationMenuClick(renderComment.commentId)}>
      Request Confirmation
    </Menu.Item>
    {showEdit && <Menu.Item onClick={()=>onEditMenuClick(renderComment,isThread)}>Edit</Menu.Item>}
    <Menu.Item onClick={()=>onDeleteMenuClick(renderComment.commentId)}>Delete</Menu.Item>
  </Menu>
  }

  function actionDropdown(renderComment,isThread=false){
    return <Dropdown
    trigger={["click"]}
    overlay={actions(renderComment,isThread)}
    overlayClassName="comment-action-dropdown"
    className="comment-action-space"
  >
    <Button shape="circle" size="small" icon={<DownOutlined />} />
  </Dropdown>
  }

  //check message have open treads
  const isThreadAvailable = comment?.replies?.length === 0 ? false : true || null
  // const isThreadAvailable = false
  const [expanded, setExpanded] = useState(true)
  const [replyComment, setReplyComment] = useState(null);


  function openThreadReply() {
    setExpanded(!expanded)
  }

  // const setReplyToComment = (comment) => {
  //   setReplyComment(comment)
  //   // setModalValues("commentsDrawer", {
  //   //   replyToCommentId: comment.id,
  //   //   replyToCommentType: comment.type,
  //   // });
  //   // if (inputRef.current) {
  //   //   inputRef.current.focus();
  //   // }
  // };

  function onQuoteCommentClick(comment, type) {
    comment.reply_type = type;
    setReplyComment(comment);
  }

  function getActions({ comment, repliesCount, unreadCount, canReplyInThread, canQuote } = { canReplyInThread: false, canQuote: false }) {
    let replyText = "Reply in thread";
    if (repliesCount == 1) {
      replyText = "Reply"
    } else if (repliesCount > 1) {
      replyText = "Replies"
    }

    let unreadText = "";
    if (unreadCount > 0) {
      unreadText = `${unreadCount} Unread`
    }

    const replyInThread = <span onClick={openThreadReply} key="comment-basic-dislike">
      <CommentOutlined /> &nbsp;
      {`${repliesCount ? repliesCount : ""}`}&nbsp;
      {replyText}
      &ensp;
      {unreadCount > 0 &&
        < Badge style={{ margin: '0px 4px' }} status="processing" text="" />
      }
      {unreadText}
    </span>;

    // const quote = <span onClick={() => { onQuoteCommentClick(comment, 'reply') }} key="comment-basic-reply-to">
    //   <ReplyIcon width={12} height={10} fill={"gray"}></ReplyIcon> &nbsp;
    //   Quote
    // </span>

    const actions = [
    ];

    // if (canReplyInThread) actions.push(replyInThread)
    // if (canQuote) actions.push(quote)
    
    return actions;
  }

  // useEffect(() => {
  //   setExpanded(comment?.replies?.length > 0)
  // }, [])

  function RejectApproveComponent(commentId){
    return <Row className="request-edit-approval">
    <div className="title">
      <WarningOutlined /> <strong>Action required</strong>
    </div>
    <div className="actions">
      <Popconfirm
        overlayStyle={{ zIndex: 999999999999999 }}
        placement="bottom"
        title="Are you sure you want to reject this edit?"
        onConfirm={()=>onRequestEditReject(commentId)}
      >
        <Button shape="round" type="default">
          Reject
        </Button>
      </Popconfirm>

      {/* <Popconfirm
        overlayStyle={{ zIndex: 999999999999999 }}
        placement="bottom"
        title="Are you sure you want to approve this edit?"
        onConfirm={()=>onRequestEditApprove(commentId)}
      >
        <Button shape="round" type="primary">
          Approve
        </Button>
      </Popconfirm> */}
      
       <Button shape="round" type="primary" onClick={()=>onRequestEditApprove(commentId)}>
          Approve
        </Button>
    </div>
  </Row>
  }

  return (
    <div style={{width:'100%'}} ref={commentRow} key={comment.commentId}>
      {
        <Row
          id={`comment-${getCommentComponentData(comment).id}`}
          className={`space-left ${alignRight ? "align-comment-left" : ""
            } ${isBeingEdited ? "comment-being-edited" : ""} ${requestEditedNotOwner ? "request-edited-comment" : ""
            }`}
          style={{ alignItems: 'center' }}
        >
           <div className="block">
            {/* {
              comment.read ? null :<div class="divider">
              <span>You have unread message</span>
             </div>
            } */}
            <div className="grid-container" style={{position:'relative',marginLeft:10}}>
              {
                isAddCommentPending ?'Loading' : <div>
               <Comment
                  style={{width: 'auto', paddingTop:!comment.read? 8:0}}
                  actions={getActions({ canQuote: false, comment: comment, canReplyInThread: true, repliesCount: comment.replies?.length, unreadCount: comment.replies?.reduce((i, r) => r.read ? i : i + 1, 0) })}
                  author={<p style={{ marginLeft: '-8px' /* Author text is center aligned. FIXME ? */ }}>{getCommentComponentData(comment).author}</p>}
                  className={`comment-container`}
                  avatar={getCommentComponentData(comment).avatar}
                  content={getCommentComponentData(comment).content}
                  datetime={getCommentComponentData(comment).datetime}
                  key={comment.commentId}
                >
                  {expanded &&
                    comment.replies.map((reply) => {
                      const replyCompData = getCommentComponentData(reply)
                      const isThreadOwner = sub === reply?.owner?.id;
                      const threadRequestEditedNotOwner =!isThreadOwner &&
                      reply?.requestEdited &&
                      typeof reply.editApproved !== "boolean";
                      // const showDropdown =
                      // isParaplanner &&
                      // isOwner &&
                      // [REQUEST_STATUS.inReview_reviewing, REQUEST_STATUS.processing].includes(
                      //   status
                      // ) &&
                      // !comment.loading;
                      const showDropDownThread = isParaplanner && isThreadOwner && [REQUEST_STATUS.inReview_reviewing, REQUEST_STATUS.processing].includes(
                           status
                        )

                      const defaultStyles = {display: 'flex',  alignItems: 'start', justifyContent: 'space-between', gap: 10,width:"100%"}
                  
                      return <div key={replyCompData.id} style={showDropDownThread? defaultStyles: {width:'100%',position:'relative'}}>
                            <Comment
                          style={{paddingTop:!comment.read? 8:0}}
                            actions={getActions({ canQuote: true, comment: reply, repliesCount: comment.replies?.length, unreadCount: comment.replies?.reduce((i, r) => r.read ? i : i + 1, 1) })}
                            author={<p style={{ marginLeft: '-8px' /* Author text is center aligned. FIXME ? */ }}>{replyCompData.author}</p>}
                            className={`comment-container`}
                            // FIXME Correct avatar is not loading. Loads the parent comment avatar ?
                            avatar={replyCompData.avatar}
                            content={replyCompData.content}
                            datetime={replyCompData.datetime}
                        />
                        {/* {!reply.read && <div className="message">
                          <span>New Message</span>
                        </div>} */}
                     {showDropDownThread ? actionDropdown({commentId:reply.commentId,content:reply.content},true) : null}
                     {threadRequestEditedNotOwner && RejectApproveComponent(reply.commentId)}
                      </div>
                     
                    })
                    
                  }
                </Comment>     
               {/* {
                !comment.read &&  <div className="message">
                <span>New Message</span>
                    </div>
               } */}
              </div>
              }
              {showDropdown ? actionDropdown({commentId:comment.commentId,content:comment.content}) : null}
            </div>
            {/* Show unread count */}
           
            {requestEditedNotOwner && (
              RejectApproveComponent(comment.commentId)
            )}

            {/* Reply to a Threads */}
            {expanded &&
              <section style={{ marginLeft: 20,marginTop:comment.read ? 10 :5, marginBottom:20 }} className="add-comment">
                <AddComment
                  entityType={COMMENT.ENTITY_TYPE}
                  entityId={COMMENT.ENTITY_ID}
                  onCancelReplyComment={() => setReplyComment(null)}
                  replyComment={replyComment}
                  parentId={comment.commentId} 
                  editComment={selectedEditThreadComment}
                  resetThreadEditComment={resetThreadEditComment}
                  handleEditComment={handleEditComment}
                  label="Reply to the thread"
                />
              </section>}
          </div>

        </Row>
      }
      <Modal title="Comment Delete" open={isDeleteConfirmOpen} onOk={onDeleteConfirm} onCancel={onDeleteCancel} zIndex={999999999999999}>
        <p>Are you sure you want to delete this comment?</p>
      </Modal>
    </div>
  );
};

const CommentsList =({
    comments,
    isDrawerVisible,
    handleDeleteComment,
    handleDeleteFile,
    setEditCommentId,
    editCommentId,
    handleRequestConfirmation,
    handleRequestEditApproval,
    handleAddComment,
    setShowAddComment,
    handleEditComment
  }) => {
    const list = useRef(null);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewTitle, setPreviewTitle] = useState("");
    const [previewFileUrl, setPreviewFileUrl] = useState(null);
    const [previewFileType, setPreviewFileType] = useState(null);

    const handlePreviewFile = async ({
      commentId,
      attachementId,
      fileTitle,
      extension,
      showModal = true,
    }) => {
      try {
        const url = await getFileAttachment({
          commentId: commentId,
          attachementId: attachementId,
        });

        if (showModal) {
          setPreviewVisible(true);
          setPreviewTitle(fileTitle);
          setPreviewFileUrl(url);
          setPreviewFileType(getFileType(extension));
        }

        return url;
      } catch (error) {
        message.error(`Couldn't preview ${fileTitle}`);
      }
    };

    const handleModalClose = () => {
      setPreviewVisible(false);
      setPreviewTitle("");
      setPreviewFileUrl(null);
      setPreviewFileType(null);
    };

    useEffect(() => {
      if (isDrawerVisible) {
        const requestEditedComments = document.querySelectorAll(
          ".request-edited-comment:not(.scrolled)"
        );

        if (requestEditedComments && requestEditedComments.length > 0) {
          requestEditedComments.forEach(el => {
            el.classList.add("scrolled");
          });
          requestEditedComments[0].scrollIntoView(true);
          return;
        }

        if (list) {
          list.current.scrollIntoView(false);
        }
      }
    }, [comments, isDrawerVisible]);
  
    const [acknowledgeComments]= useAcknowledgeSingleComment()


    function sendThreadAcknowledgement(replies){
      for (let index = 0; index < replies.length; index++) {
        if(!replies[index].read){
          setTimeout(()=>{
            acknowledgeComments({commentId:replies[index].commentId})
          },10000)
        }
      }
    }
 
    // Set timeout, so it has enough time to get user's attention.
    function onReadComment(visibleCommentOnViewport){
      //Skip for new comment
      if(visibleCommentOnViewport.read===undefined) return
      // Send acknowledgement for parent comment
      if(!visibleCommentOnViewport.read){
        setTimeout(() => {
          acknowledgeComments({commentId:visibleCommentOnViewport.commentId})
        }, 7000);
        if(!visibleCommentOnViewport.replies.length) return
        sendThreadAcknowledgement(visibleCommentOnViewport.replies)
      }else{
        if(!visibleCommentOnViewport.replies.length) return
        sendThreadAcknowledgement(visibleCommentOnViewport.replies)
      }
    }

    let currentSection = comments[0]?.entityType;
    
    return (
      <div
        ref={list}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "flex-start",
        }}
      >
        {comments.map(item => {
          return (
            <>
              {currentSection != item.entityType && (currentSection = item.entityType) &&
                <Divider>
                    <span>{tabEntityTypeTitles[currentSection] ?? ""}</span>
                </Divider>
              }
              <CommentsListItem
                key={item.commentId}
                isBeingEdited={editCommentId === item.commentId}
                replyToId={item.replyToId}
                comment={item}
                handlePreviewFile={handlePreviewFile}
                handleDeleteComment={handleDeleteComment}
                handleDeleteFile={handleDeleteFile}
                setEditCommentId={setEditCommentId}
                handleRequestConfirmation={handleRequestConfirmation}
                handleRequestEditApproval={handleRequestEditApproval}
                handleAddComment={handleAddComment}
                onReadComment={onReadComment}
                setShowAddComment={setShowAddComment}
                handleEditComment={handleEditComment}
            />
            </>
          );
        })}
        <Modal
          className="file-preview-modal"
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={handleModalClose}
          zIndex={10000000000}
          bodyStyle={{
            minHeight: 450,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          width="100%"
          style={{ maxWidth: 800 }}
          centered
        >
          <DocViewer
            pluginRenderers={DocViewerRenderers}
            documents={[
              {
                uri: previewFileUrl,
                fileType: previewFileType,
              },
            ]}
            config={{
              header: {
                disableHeader: true,
              },
            }}
            style={{ height: "100%", width: "100%", minHeight: 450 }}
          />
        </Modal>
      </div>
    );
       
  }

export default CommentsList;
