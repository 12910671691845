export const REDUX_KEY = {
  REQUEST: "REQUEST",
  INVESTMENT: "INVESTMENT",
  INVESTMENT_RESEARCH: "INVESTMENT_RESEARCH",
  INSURANCE_RESEARCH: "INSURANCE_RESEARCH",
  INSURANCE: "INSURANCE",
  ESTIMATION: "ESTIMATION",
  BASIC_DETAILS: "BASIC_DETAILS",
  RECOMMENDED_STRATEGIES: "RECOMMENDED_STRATEGIES",
  ANCILLARY: "ANCILLARY",
  MODALS: "MODALS",
  CLEANUP: "CLEANUP",
};

export const REQUEST_STATUS = {
  predraft: "predraft",
  draft: "draft",
  submitted: "submitted",
  inReview_needsAttnPL: "inReview_needsAttnPL",
  inReview_reviewing: "inReview_reviewing",
  inReview_needsAttnExt: "inReview_needsAttnExt",
  processing: "processing",
  complete: "complete",
};

export const REQUEST_STATUS_LABELS = {
  predraft: {
    text: "Pre-Draft",
    subtext: "",
    ptext: "Pre-Draft",
    psubtext: "",
  },
  draft: {
    text: "Draft",
    subtext: "",
    ptext: "Draft",
    psubtext: "",
  },
  submitted: {
    text: "Submitted",
    subtext: "Pending review",
    ptext: "Submitted",
    psubtext: "Pending review",
  },
  inReview_needsAttnPL: {
    text: "Submitted",
    subtext: "",
    ptext: "In review",
    psubtext: "Start review",
  },
  inReview_reviewing: {
    text: "In review",
    subtext: "",
    ptext: "In review",
    psubtext: "Reviewing",
  },
  inReview_needsAttnExt: {
    text: "In review",
    subtext: "Adviser attention",
    ptext: "In review",
    psubtext: "Adviser attention",
  },
  processing: {
    text: "Processing",
    subtext: "",
    ptext: "Processing",
    psubtext: "",
  },
  complete: {
    text: "Complete",
    subtext: "",
    ptext: "Complete",
    psubtext: "",
  },
};

// value in milliseconds
export const WAIT_BEFORE_SUBMIT = 3000;

// waiting time in milliseconds before closing Current Position drawer
export const WAIT_BEFORE_CLOSE = 2000;

// can be a number in px or a string in percentage(of the screen)
export const COMMENT_DRAWER_WIDTH = "60%";

// Max characters to show in summary drawer preview and reply to previews
export const COMMENT_PREVIEW_MAX_LENGTH = 100;

// can be a number in px or a string in percentage(of the comments drawer)
export const COMMENT_MAX_WIDTH = "90%";

// waiting time in milliseconds before auto creating investment column
export const WAIT_BEFORE_AUTO_CREATE_INV = 3000;

// default dela for useThrottle hook
export const DEFAULT_THROTTLE_DELAY = 2000;

// can be a number in px or a string in percentage(of the screen)
export const COMPLETE_DRAWER_WIDTH = "80%";

//For Chat(comments) Feature
export const COMMENT={
  ENTITY_TYPE:'chat',
  ENTITY_ID:'chat'
}
