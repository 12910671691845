import { combineReducers } from "redux";
import investmentTable, {
  INITIAL_STATE as investmentTableInitialState,
} from "./investmentTable";
import investmentResearch, {
  INITIAL_STATE as investmentResearchInitialState,
} from "./investmentResearch";
import insuranceResearch, {
  INITIAL_STATE as insuranceResearchInitialState,
} from "./insuranceResearch";
import insuranceTable, {
  INITIAL_STATE as insuranceTableInitialState,
} from "./insuranceTable";
import estimation, {
  INITIAL_STATE as estimationInitialState,
} from "./estimation";
import basicDetails, {
  INITIAL_STATE as basicDetailsInitialState,
} from "./basicDetails";
import recommendedStrategies, {
  INITIAL_STATE as recommendedStrategiesInitialState,
} from "./recommendedStrategies";
import ancillary, { INITIAL_STATE as ancillaryInitialState } from "./ancillary";
import modals, { INITIAL_STATE as modalsInitialState } from "./modals";
import request, { INITIAL_STATE as requestInitialState } from "./request";

import { REDUX_KEY } from "../../utils/constants";

const appReducer = combineReducers({
  [`${REDUX_KEY.REQUEST}`]: request,
  [`${REDUX_KEY.INVESTMENT}`]: investmentTable,
  [`${REDUX_KEY.INVESTMENT_RESEARCH}`]: investmentResearch,
  [`${REDUX_KEY.INSURANCE_RESEARCH}`]: insuranceResearch,
  [`${REDUX_KEY.INSURANCE}`]: insuranceTable,
  [`${REDUX_KEY.ESTIMATION}`]: estimation,
  [`${REDUX_KEY.BASIC_DETAILS}`]: basicDetails,
  [`${REDUX_KEY.RECOMMENDED_STRATEGIES}`]: recommendedStrategies,
  [`${REDUX_KEY.ANCILLARY}`]: ancillary,
  [`${REDUX_KEY.MODALS}`]: modals,
});

const getCleanUpState = state => ({
  [`${REDUX_KEY.REQUEST}`]: {
    ...requestInitialState,
    information: state[REDUX_KEY.REQUEST].information,
    advisors: state[REDUX_KEY.REQUEST].advisors,
  },
  [`${REDUX_KEY.INVESTMENT}`]: {
    ...investmentTableInitialState,
    investmentProducts: state[REDUX_KEY.INVESTMENT].investmentProducts,
  },
  [`${REDUX_KEY.INVESTMENT_RESEARCH}`]: investmentResearchInitialState,
  [`${REDUX_KEY.INSURANCE_RESEARCH}`]: insuranceResearchInitialState,
  [`${REDUX_KEY.INSURANCE}`]: insuranceTableInitialState,
  [`${REDUX_KEY.ESTIMATION}`]: estimationInitialState,
  [`${REDUX_KEY.BASIC_DETAILS}`]: {
    ...basicDetailsInitialState,
    uiOptions: state[REDUX_KEY.BASIC_DETAILS].uiOptions,
    practiceServices: state[REDUX_KEY.BASIC_DETAILS].practiceServices,
  },
  [`${REDUX_KEY.ANCILLARY}`]: {
    ...ancillaryInitialState,
    projectionRequirements: state[REDUX_KEY.ANCILLARY].projectionRequirements,
  },
  [`${REDUX_KEY.RECOMMENDED_STRATEGIES}`]: {
    ...recommendedStrategiesInitialState,
  },
  [`${REDUX_KEY.MODALS}`]: {
    ...modalsInitialState,
    digitalRequestDrawer: state[REDUX_KEY.MODALS].digitalRequestDrawer,
    settingsDrawer: state[REDUX_KEY.MODALS].settingsDrawer,
    assignRequestDrawer: state[REDUX_KEY.MODALS].assignRequestDrawer,
    holdDrawer: { ...state[REDUX_KEY.MODALS].holdDrawer, selectedReasons: [] },
  },
});

const rootReducer = (state, action) => {
  if (action.type === REDUX_KEY.CLEANUP) {
    return getCleanUpState(state);
  }
  return appReducer(state, action);
};

export default rootReducer;
