import PesTokenizer from "@planpod/pp-lib-pes-parser/dist/tokens/PesTokenizer";
import React from "react";

export const makeId = length => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getParsedJSON = json => {
  return JSON.parse(json);
};

export const generateTreeData = element => {
  let data = "";
  const keys = Object.keys(element);
  for (var i = 0; i < keys.length; i++) {
    const key = keys[i];
    data += `{ "title": "${key}",`;
    if (element[key].text) {
      data += `"value": "${element[key].text}" }${
        i !== keys.length - 1 ? "," : ""
      }`;
    } else {
      data += `"disabled": true, "children" : [${generateTreeData(
        element[key]
      )}] }${i !== keys.length - 1 ? "," : ""}`;
    }
  }
  return data;
};

export const getTreeData = json => {
  const parsedJSON = getParsedJSON(json);
  const treeDataString = generateTreeData(parsedJSON);
  return JSON.parse("[" + treeDataString + "]");
};

export const splitTextByFields = string =>
  string.split(/({{.*?}}|_.*?_|\*.*?\*|\\n)/);

export const getFieldsFromString = string => {
  return string.match(/\{{.*?\}}/g).map(x => x.replace(/[{{}}]/g, ""));
};

export const getXplanProducts = references => {
  const xplanProducts =
    references && references["xplan-products"]
      ? Object.entries(references["xplan-products"]).map(reference => ({
          key: reference[0],
          value: reference[1],
          group: "xplan-products",
        }))
      : [];

  return xplanProducts;
};


export const getReferencedOptions = (type, references) => {
  const key = type.replace(/[{}]/g, "");
  const recommendations = references
    ? Object.entries(references["recommendations"]).map(reference => ({
      key: reference[0],
      value: reference[1],
      group: "recommendations",
    }))
    : [];
  const xplanProducts = references
    ? Object.entries(references["xplan-products"]).map(reference => ({
      key: reference[0],
      value: reference[1],
      group: "xplan-products",
    }))
    : [];
  const optionsArr =
    references && references[key]
      ? [...recommendations, ...xplanProducts]
      : [];
  return { optionsArr, key, recommendations, xplanProducts };
}


export const getOptionsBetweenBrackets = (string, references = {}) => {
  const matchedString = string.match(/\[.*?\]/g);
  if (matchedString.length) {
    const referencesString = (matchedString[0] || "").match(/\{.*?\}/g);
    if (referencesString && referencesString.length) {
      const key = (referencesString[0] || "").replace(/[{}]/g, "");
      const recommendations = references
        ? Object.entries(references["recommendations"]).map(reference => ({
            key: reference[0],
            value: reference[1],
            group: "recommendations",
          }))
        : [];
      const xplanProducts = references
        ? Object.entries(references["xplan-products"]).map(reference => ({
            key: reference[0],
            value: reference[1],
            group: "xplan-products",
          }))
        : [];
      const optionsArr =
        references && references[key]
          ? [...recommendations, ...xplanProducts]
          : [];
      return { optionsArr, key, recommendations, xplanProducts };
    }
  }
  const key = string.split(".")[1].split(":")[0];
  // const optionsArr = matchedString.map(x => x.replace(/[[\]]/g, ""))[0].split(',').map(x => x.replace(/[_]/g, " "));
  const options = matchedString
    .map(x => x.replace(/[[\]]/g, ""))[0]
    .split(",")
    .map(s => s.split(":"));
  const optionsArr = options.map(o => ({ key: o[0], value: o[1].trim() }));
  return { optionsArr, key };
};

export const getPathFromString = string => {
  return string.match(/%.*?%/g).map(x => x.replace(/[%]/g, ""))[0];
};


export const getParametersFromText = string => {
  const parser = new PesTokenizer(string);
  const pes = parser.tokenize()
  const parameters = {}

  for (const comp of pes) {
    if (comp.id != null) {
      const key = `var${comp.id}`
      parameters[key] = null
    }
  }
  return parameters;
};

export const doesPartnerExist = partner => {
  return partner.firstName !== "";
};

// Same as above (Just made another to avoid confusing names and future extension)
export const doesClientExist = client => {
  return client.firstName !== "";
};

export const formatMoney = (
  number,
  minimumFractionDigits = 0,
  maximumFractionDigits = 0
) => {
  return number.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits,
    maximumFractionDigits,
  });
};

export const isNumber = text => {
  if (text && text.trim() !== "") {
    let stripped = text.replace(/\s/g, "").replace(/[,$]/g, "");
    let isNum = /^\d*(\.\d+)?$/.test(stripped);

    return isNum;
  }
  return false;
};

export const formatCurrencyIfCurrency = text => {
  if (text && text.trim() !== "") {
    let stripped = text.replace(/\s/g, "").replace(/[,$]/g, "");
    let isNum = /^\d*(\.\d+)?$/.test(stripped);

    if (isNum && stripped !== "") {
      return formatMoney(parseFloat(stripped));
    } else {
      return text;
    }
  }
  return text;
};

export const sumCover = (cover, isSuper = false) => {
  if (!cover) {
    return 0;
  }
  return cover.reduce((a, c) => {
    if (c.withinSuper || !isSuper) {
      return a + c.value;
    }
    return a;
  }, 0);
};

export const truncateString = (str = "", maxLen = 30) => {
  if (str.length <= 30) return str;
  else return str.slice(0, maxLen - 1);
};

export const getUrl = () => {
  return `https://${process.env.REACT_APP_BASE_HOST}/auth`;
};

export const getRealm = () => {
  let token = JSON.parse(sessionStorage.getItem("__keycloak_token_parsed__"));
  if (token) {
    return token.planpod_dealer_group;
  }

  let pathname = window.location.pathname;
  let realm = pathname.split("/")[1].toLowerCase();
  return realm;
};

export const getOwner = ({owner, client, partner})=>{
  let ownerName = owner;
  if (owner === "client") {
    ownerName = doesClientExist(client) ? client.firstName : "Client";
  } else if (owner === "partner") {
    ownerName = doesPartnerExist(partner) ? partner.firstName : "Partner";
  } else if (owner === "joint") {
    const clientName = doesClientExist(client) ? client.firstName : "Client";
    const partnerName = doesPartnerExist(partner)
      ? partner.firstName
      : "Partner";
    ownerName = `${clientName} and ${partnerName}`;
  } else {
    ownerName = "Owner";
  }
  return ownerName
}

export const replaceOwner = ({ dropdownValue, owner, client, partner }) => {
  let ownerName = owner;
  if (owner === "client") {
    ownerName = doesClientExist(client) ? client.firstName : "Client";
  } else if (owner === "partner") {
    ownerName = doesPartnerExist(partner) ? partner.firstName : "Partner";
  } else if (owner === "joint") {
    const clientName = doesClientExist(client) ? client.firstName : "Client";
    const partnerName = doesPartnerExist(partner)
      ? partner.firstName
      : "Partner";
    ownerName = `${clientName} and ${partnerName}`;
  } else {
    ownerName = "Owner";
  }

  const text = dropdownValue?.replace(/%owner%/g, ownerName) || "";

  return text;
};

export const setSoaId = soaId => {
  window.localStorage.setItem("soaId", soaId);
  if (window.__insp && process.env.REACT_APP_LOG_INSPECTLET === "true") {
    window.__insp.push([
      "tagSession",
      {
        workId: `soa_${soaId}`,
      },
    ]);
  }
};

export const getSoaId = () => {
  let soaId = window.localStorage.getItem("soaId");

  if (soaId) {
    return soaId;
  }

  let pathname = window.location.pathname;
  if (pathname.split("/").filter(Boolean).length < 3) {
    return null;
  }
  let soaIdFromWindow = pathname.split("/").filter(Boolean)[2];
  setSoaId(soaIdFromWindow);
  return soaIdFromWindow;
};

export const devLog = (...messages) => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    console.log(messages);
  }
};

export const applyProps = (component, props) => {
  if (React.isValidElement(component)) {
    return React.cloneElement(component, props);
  }
  return component;
};

export const formatResearchProductOption=(product,productIdx)=>{
  return `Research Comparison ${productIdx + 1}
  ${
    product?.owner
      ? ` - ${
          product?.owner.charAt(0).toUpperCase() +
          product?.owner.slice(1).toLowerCase()
        }`
      : ""
  }`
}

export const isDevEnv = process.env.NODE_ENV === "development";

export const isWorkflowDev = process.env.REACT_APP_ENV === "dev";
export const isWorkflowPrd = process.env.REACT_APP_ENV === "prd";
