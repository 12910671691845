import { useMutation } from "react-query";
import { useSetStatus } from "../../redux/hooks/request";
import ApiUtils from "../../utils/apiUtils";
import { getSoaId } from "../../utils/common";

const triggerRequest = async ({ trigger, body = {}, soaId = getSoaId() }) => {
  return ApiUtils.DSRF_HTTPS.put(`/soa/${soaId}?trigger=${trigger}`, {
    ...body,
  });
};

const useTriggerRequest = () => {
  const setStatus = useSetStatus();

  const { mutateAsync, isLoading, status, data, error } = useMutation(
    triggerRequest,
    {
      onSuccess: data => {
        if (data.data.result) {
          setStatus(data.data.final_state);
        }
      },

      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      throwOnError: true,
    }
  );

  return [mutateAsync, { isLoading, status, data, error }];
};

export default useTriggerRequest;
