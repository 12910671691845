import { createSelector } from "reselect";
import { REDUX_KEY } from "../../../utils/constants";

const reducerKey = REDUX_KEY.BASIC_DETAILS;

export const getClient = createSelector(
  state => state[reducerKey].client,
  client => client
);

export const getPartner = createSelector(
  state => state[reducerKey].partner,
  partner => partner
);

export const getSmsf = createSelector(
  state => state[reducerKey].smsf,
  smsf => smsf
);

export const getTrust = createSelector(
  state => state[reducerKey].trust,
  trust => trust
);

export const getCompany = createSelector(
  state => state[reducerKey].company,
  company => company
);

export const getServiceOffering = createSelector(
  state => state[reducerKey].soaRequestDetails.serviceOffering,
  serviceOffering => serviceOffering
);

export const getServiceOfferingAddOns = createSelector(
  state => state[reducerKey].soaRequestDetails.serviceOfferingAddOns,
  serviceOfferingAddOns => serviceOfferingAddOns
);

export const getUiOptions = createSelector(
  state => state[reducerKey].uiOptions,
  uiOptions => uiOptions
);

export const getPracticeServices = createSelector(
  state => state[reducerKey].practiceServices,
  practiceServices => practiceServices
);
