import { Button } from "antd";
import React from "react";
import { LoadingBackground } from "../../views/LoginLayout/styled";
import Loading from "../Loading";

// https://reactjs.org/docs/error-boundaries.html
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("ERROR CAUGHT", { error, errorInfo });
    if (window.__insp && process.env.REACT_APP_LOG_INSPECTLET === "true") {
      window.__insp.push(["tagSession", "ui-error"]);
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <LoadingBackground>
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <p style={{ color: "red" }}>Oh no! Something went wrong...</p>
            <Button
              type="ghost"
              onClick={() => {
                window.location.reload();
              }}
            >
              Reload Window
            </Button>
          </div>
        </LoadingBackground>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
