import { createSelector } from "reselect";
import { REDUX_KEY } from "../../../utils/constants";

const reducerKey = REDUX_KEY.REQUEST;

export const getActiveTab = createSelector(
  state => state[reducerKey].activeTab,
  activeTab => activeTab
);

export const getCreatorId = createSelector(
  state => state[reducerKey].creatorId,
  creatorId => creatorId
);

export const getProductionAllowed = createSelector(
  state => state[reducerKey].productionAllowed,
  creatorId => creatorId
);

export const getFirstSubmissionComplete = createSelector(
  state => state[reducerKey].firstSubmissionComplete,
  firstSubmissionComplete => firstSubmissionComplete
);

export const getStatus = createSelector(
  state => state[reducerKey].status,
  status => status
);

export const getInfoBanners = createSelector(
  state => state[reducerKey].information,
  information => information.filter(i => i.type === "banner")
);

export const getInfoNotifications = createSelector(
  state => state[reducerKey].information,
  information => information.filter(i => i.type === "notification")
);

export const getAdvisors = createSelector(
  state => state[reducerKey].advisors,
  advisors => advisors
);

export const getAdvisorId = createSelector(
  state => state[reducerKey].advisorId,
  advisorId => advisorId
);

export const getCommentsAllowed = createSelector(
  state => state[reducerKey].commentsAllowed,
  commentsAllowed => commentsAllowed
);

export const getEditingAllowed = createSelector(
  state => state[reducerKey].editingAllowed,
  editingAllowed => editingAllowed
);

export const getReadOnly = createSelector(
  state => state[reducerKey].readOnly,
  readOnly => readOnly
);

export const getSoaVersion = createSelector(
  state => state[reducerKey].soaVersion,
  soaVersion => soaVersion
);

export const getXplanProductListVersion = createSelector(
  state => state[reducerKey].xplanProductListVersion,
  version => version
)

export const getIsSyncing = createSelector(
  state => state[reducerKey].isSyncing,
  isSyncing => isSyncing
);

export const getInitialData = createSelector(
  state => state[reducerKey].initialData,
  initialData => initialData
);

export const getFeesVersion = createSelector(
  state => state[reducerKey].feesVersion,
  feesVersion => feesVersion
);

export const getPinningAllowed = createSelector(
  state => state[reducerKey].pinningAllowed,
  pinningAllowed => pinningAllowed
);

export const getPricingScheduleAvailable = createSelector(
  state => state[reducerKey].pricingScheduleAvailable,
  pricingScheduleAvailable => pricingScheduleAvailable
);
