import { createSelector } from "reselect";
import { REDUX_KEY } from "../../../utils/constants";

const reducerKey = REDUX_KEY.RECOMMENDED_STRATEGIES;

export const getAutoCreateData = createSelector(
  state => state[reducerKey].autoCreate,
  autoCreate => autoCreate
);

export const getFsoaStrategies = createSelector(
  state => state[reducerKey].fsoaStrategies,
  fsoaStrategies => fsoaStrategies
);

export const getAlerts = createSelector(
  state => state[reducerKey].alerts,
  alerts => alerts
);

export const getAllStrategies = createSelector(
  state => state[reducerKey].strategies,
  strategies => strategies
);

export const getStrategy = id =>
  createSelector(getAllStrategies, strategies =>
    strategies.find(s => s.id === id)
  );
