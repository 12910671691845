import { useContext, useEffect } from "react";
import { AuthContext } from "../Auth";
import { useGetEditingAllowed, useSetReadOnly } from "../redux/hooks/request";

export function useReadOnly() {
  const setReadOnly = useSetReadOnly();
  const editingAllowed = useGetEditingAllowed();
  const {
    parsedToken: {
      realm_access: { roles },
    },
  } = useContext(AuthContext);

  const isParaplanner = roles && roles.includes("paraplanner");

  useEffect(() => {
    if (!isParaplanner) {
      setReadOnly(!editingAllowed);
    }
  }, [isParaplanner, editingAllowed]);
}

export default useReadOnly;
