import { createReducer } from "reduxsauce";
import { Types } from "../../actions/insuranceTable";

// the initial state of this reducer
export const INITIAL_STATE = {
  strategies: [],
  recommendedQuotes: [],
  alternativeQuotes: [],
  overallComments: "",
  needAnalysis: "",
  needAnalysisFile:""
};

const getInitialStrategy = (id, description, key, owner) => ({
  id,
  description,
  key,
  owner: owner.toLowerCase(),
  recommendedQuotes: [],
  noOfPolicies: null,
  alternativeQuotes: [],
  specificProviders: "",
  additionalComments: "",
});

export const hydrate = (
  state = INITIAL_STATE,
  {
    strategies = [],
    recommendedQuotes = [],
    alternativeQuotes = [],
    overallComments = "",
    needAnalysis = "",
    needAnalysisFile = ""
  }
) => {
  return {
    ...state,
    strategies,
    alternativeQuotes,
    recommendedQuotes,
    overallComments,
    needAnalysis,
    needAnalysisFile
  };
};

export const addStrategy = (
  state = INITIAL_STATE,
  { id = null, description = "", key = "", owner = "" }
) => ({
  ...state,
  strategies: [
    ...state.strategies,
    getInitialStrategy(id, description, key, owner),
  ],
});

export const deleteStrategy = (state = INITIAL_STATE, { id = null }) => {
  return {
    ...state,
    strategies: state.strategies.filter(s => s.id !== id),
  };
};

export const editStrategyDescription = (
  state = INITIAL_STATE,
  { id = null, description = "" }
) => {
  return {
    ...state,
    strategies: state.strategies.map(s => {
      if (s.id === id) {
        return { ...s, description };
      }
      return s;
    }),
  };
};

export const editStrategyQuotes = (
  state = INITIAL_STATE,
  { id = null, quoteType = "", quotes = [] }
) => {
  return {
    ...state,
    strategies: state.strategies.map(s => {
      if (s.id === id) {
        return {
          ...s,
          [quoteType]: quotes,
        };
      }
      return s;
    }),
  };
};

export const editStrategySpecificProviders = (
  state = INITIAL_STATE,
  { id = null, specificProviders = "" }
) => {
  return {
    ...state,
    strategies: state.strategies.map(s => {
      if (s.id === id) {
        return { ...s, specificProviders };
      }
      return s;
    }),
  };
};

export const editStrategyNoOfPolicies = (
  state = INITIAL_STATE,
  { id = null, noOfPolicies = null }
) => {
  return {
    ...state,
    strategies: state.strategies.map(s => {
      if (s.id === id) {
        return { ...s, noOfPolicies };
      }
      return s;
    }),
  };
};

export const editStrategyAdditionalComments = (
  state = INITIAL_STATE,
  { id = null, additionalComments = "" }
) => {
  return {
    ...state,
    strategies: state.strategies.map(s => {
      if (s.id === id) {
        return { ...s, additionalComments };
      }
      return s;
    }),
  };
};

export const setQuotes = (
  state = INITIAL_STATE,
  { quoteType = "", quotes = [] }
) => ({
  ...state,
  [quoteType]: quotes,
});

export const setOverallComments = (
  state = INITIAL_STATE,
  { overallComments }
) => ({
  ...state,
  overallComments,
});

export const setNeedAnalysis = (state = INITIAL_STATE, { needAnalysis }) => {
  if (needAnalysis === "Required" || needAnalysis === "Not Required") {
    return {
      ...state,
      needAnalysis,
    };
  }
  return state;
};

export const setNeedAnalysisFile = (state = INITIAL_STATE, { needAnalysisFile }) => {
  if(needAnalysisFile.length > 1){
    return {
      ...state,
      needAnalysisFile,
    };
  }
  return state;
};

export const HANDLERS = {
  [Types.ADD_STRATEGY]: addStrategy,
  [Types.DELETE_STRATEGY]: deleteStrategy,
  [Types.EDIT_STRATEGY_DESCRIPTION]: editStrategyDescription,
  [Types.EDIT_STRATEGY_SPECIFIC_PROVIDERS]: editStrategySpecificProviders,
  [Types.EDIT_STRATEGY_ADDITIONAL_COMMENTS]: editStrategyAdditionalComments,
  [Types.EDIT_STRATEGY_QUOTES]: editStrategyQuotes,
  [Types.EDIT_STRATEGY_NO_OF_POLICIES]: editStrategyNoOfPolicies,
  [Types.SET_QUOTES]: setQuotes,
  [Types.HYDRATE]: hydrate,
  [Types.SET_OVERALL_COMMENTS]: setOverallComments,
  [Types.SET_NEED_ANALYSIS]: setNeedAnalysis,
  [Types.SET_NEED_ANALYSIS_FILE]: setNeedAnalysisFile,
};

export default createReducer(INITIAL_STATE, HANDLERS);
