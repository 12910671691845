/* eslint-disable no-undef */
import React, { createContext, useContext, useRef } from "react";

export const ValidationContext = createContext({});

export const ValidationProvider = ({ children }) => {
  const validateRef = useRef(() => false);

  const setValidateRef = func => {
    validateRef.current = func;
  };

  return (
    <ValidationContext.Provider
      value={{
        validateRef: validateRef,
        setValidateRef: setValidateRef,
      }}
    >
      {children}
    </ValidationContext.Provider>
  );
};

export const useValidation = () => {
  return useContext(ValidationContext);
};
