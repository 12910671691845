import { createActions } from "reduxsauce";

import { REDUX_KEY } from "../../../utils/constants";

export const { Types, Creators } = createActions(
  {
    addStrategy: {
      id: null,
      description: "",
      key: "",
      owner: "",
    },
    deleteStrategy: {
      id: null,
    },
    editStrategyDescription: {
      id: null,
      description: "",
    },
    editStrategySpecificProviders: {
      id: null,
      specificProviders: "",
    },
    editStrategyNoOfPolicies: {
      id: null,
      noOfPolicies: null,
    },
    editStrategyAdditionalComments: {
      id: null,
      additionalComments: "",
    },
    setQuotes: {
      quoteType: "",
      quotes: [],
    },
    editStrategyQuotes: {
      id: null,
      quoteType: "",
      quotes: [],
    },
    hydrate: {
      strategies: [],
      recommendedQuotes: [],
      alternativeQuotes: [],
      overallComments: "",
      needAnalysis:"",
      needAnalysisFile:""
    },
    setOverallComments: {
      overallComments: "",
    },
    setNeedAnalysis:{
      needAnalysis:""
    },
    setNeedAnalysisFile:{
      needAnalysisFile:""
    }
  },
  { prefix: `${REDUX_KEY.INSURANCE}_` }
);
