import React from "react";

const ApproveIcon = () => {
  return (
    <svg
      width="22px"
      height="18px"
      viewBox="0 0 22 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Approve</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="P9"
          transform="translate(-1309.000000, -74.000000)"
          fill="#FFFFFF"
        >
          <g id="Group-15" transform="translate(1302.000000, 63.000000)">
            <g
              id="Actions-/-Formatting-/-list--checked-/-24"
              transform="translate(6.000000, 8.000000)"
            >
              <path
                d="M12,6 L22.5,6 L22.5,7.5 L12,7.5 L12,6 Z M4.5,7.9425 L2.5575,6 L1.5,7.0575 L4.5,10.0575 L10.5,4.0575 L9.4425,3 L4.5,7.9425 Z M12,16.5 L22.5,16.5 L22.5,18 L12,18 L12,16.5 Z M4.5,18.4425 L2.5575,16.5 L1.5,17.5575 L4.5,20.5575 L10.5,14.5575 L9.4425,13.5 L4.5,18.4425 Z"
                id="Fill"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ApproveIcon;
