import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AuthContext } from "../../../Auth";
import { Creators } from "../../actions/request";
import {
  getActiveTab,
  getAdvisorId,
  getAdvisors,
  getCommentsAllowed,
  getEditingAllowed,
  getInfoBanners,
  getInfoNotifications,
  getReadOnly,
  getStatus,
  getCreatorId,
  getFirstSubmissionComplete,
  getSoaVersion,
  getXplanProductListVersion,
  getIsSyncing,
  getInitialData,
  getFeesVersion,
  getPinningAllowed,
  getProductionAllowed,
  getPricingScheduleAvailable,
} from "../../selectors/request";

export const useSetActiveTab = () => {
  const dispatch = useDispatch();

  return activeTab => {
    dispatch(
      Creators.setActiveTab({
        activeTab,
      })
    );
  };
};

export const useGetActiveTab = () => {
  return useSelector(getActiveTab);
};

export const useSetStatus = () => {
  const dispatch = useDispatch();

  return status => {
    dispatch(
      Creators.setStatus({
        status,
      })
    );
  };
};

export const useGetStatus = () => {
  return useSelector(getStatus);
};

export const useSetInformation = () => {
  const dispatch = useDispatch();

  return information => {
    dispatch(
      Creators.setInformation({
        information: information || [],
      })
    );
  };
};

export const useInfoBanners = () => {
  return useSelector(getInfoBanners);
};

export const useInfoNotifications = () => {
  return useSelector(getInfoNotifications);
};

export const useSetAdvisors = () => {
  const dispatch = useDispatch();

  return advisors => {
    dispatch(
      Creators.setAdvisors({
        advisors,
      })
    );
  };
};

export const useGetAdvisors = () => {
  return useSelector(getAdvisors);
};

export const useSetAdvisorId = () => {
  const dispatch = useDispatch();

  // const {
  //   parsedToken: { sub },
  // } = useContext(AuthContext);

  return advisorId => {
    // TODO: NOT SURE WHY THIS WAS NEEDED
    // if (sub !== advisorId) {
    dispatch(
      Creators.setAdvisorId({
        advisorId,
      })
    );
    // }
  };
};

export const useGetAdvisorId = () => {
  return useSelector(getAdvisorId);
};

export const useSetCommentsAllowed = () => {
  const dispatch = useDispatch();

  return commentsAllowed => {
    dispatch(
      Creators.setCommentsAllowed({
        commentsAllowed,
      })
    );
  };
};

export const useGetCommentsAllowed = () => {
  return useSelector(getCommentsAllowed);
};

export const useSetCreatorId = () => {
  const dispatch = useDispatch();

  return creatorId => {
    dispatch(
      Creators.setCreatorId({
        creatorId,
      })
    );
  };
};

export const useSetProductionAllowed = () => {
  const dispatch = useDispatch();

  return productionAllowed => {
    dispatch(
      Creators.setProductionAllowed({
        productionAllowed,
      })
    );
  };
};

export const useSetFirstSubmissionComplete = () => {
  const dispatch = useDispatch();

  return firstSubmissionComplete => {
    dispatch(
      Creators.setFirstSubmissionComplete({
        firstSubmissionComplete,
      })
    );
  };
};

export const useGetCreatorId = () => {
  return useSelector(getCreatorId);
};

export const useGetProductionAllowed = () => {
  return useSelector(getProductionAllowed);
};

export const useGetFirstSubmissionComplete = () => {
  return useSelector(getFirstSubmissionComplete);
};

export const useSetEditingAllowed = () => {
  const dispatch = useDispatch();

  return editingAllowed => {
    dispatch(
      Creators.setEditingAllowed({
        editingAllowed,
      })
    );
  };
};

export const useGetEditingAllowed = () => {
  return useSelector(getEditingAllowed);
};

export const useSetReadOnly = () => {
  const dispatch = useDispatch();

  return readOnly => {
    dispatch(
      Creators.setReadOnly({
        readOnly,
      })
    );
  };
};

export const useGetReadOnly = () => {
  return useSelector(getReadOnly);
};

export const useSetSoaVersion = () => {
  const dispatch = useDispatch();

  return soaVersion => {
    localStorage.setItem("soaVersion", soaVersion);
    dispatch(
      Creators.setSoaVersion({
        soaVersion,
      })
    );
  };
};

export const useSetXplanProductListVersion = () => {
  const dispatch = useDispatch();

  return xplanProductListVersion => {
    localStorage.setItem("xplanProductListVersion", xplanProductListVersion);
    dispatch(
      Creators.setXplanProductListVersion({
        xplanProductListVersion,
      })
    );
  };
};


export const useGetSoaVersion = () => {
  return useSelector(getSoaVersion);
};

export const useGetXplanProducListVersion = () => {
  return useSelector(getXplanProductListVersion);
};


export const useSetIsSyncing = () => {
  const dispatch = useDispatch();

  return isSyncing => {
    dispatch(
      Creators.setIsSyncing({
        isSyncing,
      })
    );
  };
};

export const useGetIsSyncing = () => {
  return useSelector(getIsSyncing);
};

export const useSetInitialData = () => {
  const dispatch = useDispatch();

  return initialData => {
    dispatch(
      Creators.setInitialData({
        initialData,
      })
    );
  };
};

export const useGetInitialData = () => {
  return useSelector(getInitialData);
};

export const useSetFeesVersion = () => {
  const dispatch = useDispatch();

  return feesVersion => {
    dispatch(
      Creators.setFeesVersion({
        feesVersion,
      })
    );
  };
};

export const useGetFeesVersion = () => {
  return useSelector(getFeesVersion);
};

export const useSetPinningAllowed = () => {
  const dispatch = useDispatch();

  return pinningAllowed => {
    dispatch(
      Creators.setPinningAllowed({
        pinningAllowed,
      })
    );
  };
};

export const useGetPinningAllowed = () => {
  return useSelector(getPinningAllowed);
};

export const useSetPricingScheduleAvailable = () => {
  const dispatch = useDispatch();

  return pricingScheduleAvailable => {
    dispatch(
      Creators.setPricingScheduleAvailable({
        pricingScheduleAvailable,
      })
    );
  };
};

export const useGetPricingScheduleAvailable = () => {
  return useSelector(getPricingScheduleAvailable);
};
