import { useDispatch, useSelector } from "react-redux";
import { Creators } from "../../actions/ancillary";
import { getProjectionRequirements } from "../../selectors/ancillary";

export const useSetProjectionRequirements = () => {
  const dispatch = useDispatch();

  return payload => {
    dispatch(
      Creators.setProjectionRequirements({
        payload,
      })
    );
  };
};

export const useGetProjectionRequirements = () => {
  const values = useSelector(getProjectionRequirements);
  return values;
};
