import React from "react";
import {
  SpinnerWrapper,
  SubText,
  LoadingWrapper,
  SubTextBlack,
  SubTextRed,
} from "./styled";

const Loading = props => {
  const { subtext, color = "black" } = props;
  return (
    <LoadingWrapper>
      {color !== "error" ? (
        <>
          <SpinnerWrapper>
            <div className="main-circle" />
          </SpinnerWrapper>
          {subtext && color === "black" ? (
            <SubTextBlack>{subtext}</SubTextBlack>
          ) : (
            <SubText>{subtext}</SubText>
          )}
        </>
      ) : (
        <SubTextRed>{subtext}</SubTextRed>
      )}
    </LoadingWrapper>
  );
};

export default Loading;
