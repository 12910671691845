import React from "react";

const StartIcon = () => {
  return (
    <svg
      width="13px"
      height="14px"
      viewBox="0 0 13 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Start</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="P8"
          transform="translate(-1282.000000, -77.000000)"
          fill="#FFFFFF"
        >
          <g id="Group-17" transform="translate(1269.000000, 63.000000)">
            <g id="Group-15" transform="translate(3.000000, 8.000000)">
              <g
                id="Person-/-User-/-user--follow-/-24"
                transform="translate(7.000000, 4.000000)"
              >
                <path
                  d="M3.9375,15.7500134 C3.62683983,15.7500134 3.37499555,15.4981602 3.37499555,15.1875 L3.37499555,2.8125 C3.374199,2.61073982 3.48151827,2.42400429 3.65625,2.323125 C3.83028811,2.22264405 4.04471189,2.22264405 4.21875,2.323125 L14.90625,8.510625 C15.0824871,8.61050318 15.1914084,8.79742859 15.1914084,9 C15.1914084,9.20257141 15.0824871,9.38949682 14.90625,9.489375 L4.21875,15.676875 C4.13302336,15.725464 4.03603671,15.7506805 3.9375,15.7500134 Z M4.5,3.785625 L4.5,14.214375 L13.5,9 L4.5,3.785625 Z"
                  id="Fill"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default StartIcon;
