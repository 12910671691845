import React from "react";
import { InputNumber } from "antd";

export const DollarInput = ({ style, ...props }) => {
  return (
    <InputNumber
      {...props}
      style={{ width: "100%", ...style }}
      formatter={value =>
        `$${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
      }
      parser={value => value.replace(/\$\s?|(,*)/g, "")}
    />
  );
};

export const PercentInput = ({ style, ...props }) => {
  return (
    <InputNumber
      {...props}
      min={0}
      max={100}
      style={{ width: "100%", ...style }}
      formatter={value => `${value}%`}
      parser={value => value.replace("%", "")}
    />
  );
};
