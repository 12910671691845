import React, { useEffect, useReducer } from "react";
import { Alert, Col } from "antd";
import { WebPubSubClient } from "@azure/web-pubsub-client";
import { negotiateWpsUrl } from "../../api/digital-request";

function Notification({ message, color, id, idx, dispatch }) {
  //Close function for alert-banner
  const onClose = e => {
    dispatch({ type: "banner/close", payload: id });
    sessionStorage.setItem(`alert-dismissed-${id}`, new Date());
  };

  const top = 20 + idx * 100 + "px";

  return (
    <div
      style={{
        position: "fixed",
        top: top,
        left: "425px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "calc(100% - 650px)",
        zIndex: 9999,
      }}
    >
      <style>
        {`
          .alert-banner-content {
            color: #FFF;
          }
          .alert-banner-content a {
            color: #FFF;
            text-decoration: underline;
          }
        `}
      </style>
      <Alert
        className="alert-banner"
        description={
          <div
            style={{
              marginLeft: "15px",
              marginRight: "15px",
              marginTop: "8px",
              marginBottom: "8px",
              fontSize: "16px",
              color: "#FFF",
              textAlign: "center",
            }}
            className="alert-banner-content"
          >
            <div dangerouslySetInnerHTML={{ __html: message }}></div>
          </div>
        }
        type="error"
        closable
        onClose={onClose}
        style={{
          // boxShadow: "-2px 0px 25px white, 0px -2px 25px white",
          fontFamily: "Lato, sans-serif",
          backgroundColor: color,
          borderRadius: "10px",
          // boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.2)",
          border: "none",
          minWidth: "100%",
        }}
      />
    </div>
  );
}

const initState = {
  bannersList: [],
};

function reducer(state, action) {
  switch (action.type) {
    case "banner/add":
      const payload = action.payload;
      const isBannerExits = state.bannersList.find(
        banner => banner.id === payload.id
      );
      return {
        ...state,
        bannersList: isBannerExits
          ? [...state.bannersList]
          : [...state.bannersList, payload],
      };
    case "banner/close":
      const banners = state.bannersList.filter(
        banner => banner.id !== action.payload
      );
      return { ...state, bannersList: banners };
    case "banner/expires":
      const banner = state.bannersList.filter(banner => {
        const currentDate = new Date();
        const bannerExpiresDate = new Date(banner.to);
        if (currentDate > bannerExpiresDate)
          sessionStorage.setItem(`alert-dismissed-${banner.id}`, new Date());
        return currentDate < bannerExpiresDate;
      });
      return { ...state, bannersList: banner };
    default:
      throw new Error("Not a valid action");
  }
}

export default function Banner() {
  const [{ bannersList }, dispatch] = useReducer(reducer, initState);

  useEffect(() => {
    startup();
  }, []);

  //Check in every 1 minutes banner is expires
  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch({ type: "banner/expires" });
    }, 1000 * 60);

    return function () {
      clearInterval(intervalId);
    };
  }, []);

  async function startup() {
    const client = new WebPubSubClient({
      getClientAccessUrl: async () => {
        const { data } = await negotiateWpsUrl();
        return data.access_url
      }
    });
    client.on("server-message", e => {
      const data = e.message.data;
      onServerMessage(data);
    });
    client.on("rejoin-group-failed", () => {
      startup();
    });
    client.start();
  }

  function onServerMessage(data) {
    const banner = {};
    banner.message = data.message;
    banner.color = data.color ?? "#FC5656";
    banner.id = data.rowKey;
    banner.to = data.to;

    const modifiedAt = new Date(data.timestamp);

    let showBanner = data.display ?? false; // Display
    showBanner = showBanner && data.message && data.message.trim() != ""; // Message not empty

    if (sessionStorage.getItem(`alert-dismissed-${banner.id}`)) {
      const dismissedAt = new Date(
        sessionStorage.getItem(`alert-dismissed-${banner.id}`)
      );
      if (modifiedAt < dismissedAt) {
        showBanner = false; // User dismissed
      } else {
        // Banner has been updated after user dismissed. So show it anyway.
        // I know this block is redundant
        showBanner = showBanner && true;
      }
    }
    banner.display = showBanner;
    if (data.display) dispatch({ type: "banner/add", payload: banner });
    // setBanners(newBanners);
  }

  return (
    <>
      <Col>
        {bannersList.map((b, index) => {
          if (b.display) {
            return (
              <Notification
                key={b.id}
                message={b.message}
                color={b.color}
                idx={index}
                dispatch={dispatch}
                id={b.id}
              ></Notification>
            );
          }
        })}
      </Col>
    </>
  );
}
