import { v4 as uuid } from "uuid";
import { createReducer } from "reduxsauce";
import { Types } from "../../actions/insuranceResearch";

const getInitialPolicy = (
  { id, owner = "", policyOwnership = [] },
  noOfProductsToCondider
) => {
  let policy = {
    id: id || uuid(),
    owner,
    policyOwnership,
    coverAmount: {
      life: null,
      tpd: null,
      trauma: null,
      ip: null,
    },
    tpdFeatures: [],
    traumaFeatures: {
      selected: [],
      childCover: null,
    },
    ipFeatures: [],
    ipWaitingPeriod: "",
    ipBenefitPeriod: "",
    premiumType: [],
    premiumFrequency: [],
    loadings: "",
    commissionStructure: [],
    additionalComments: "",
    productsToConsider: new Array(noOfProductsToCondider).fill(""),
  };

  return policy;
};

export const INITIAL_STATE = {
  policies: [
    getInitialPolicy({}, 2),
    getInitialPolicy({}, 2),
    getInitialPolicy({}, 2),
  ],
  noOfProductsToCondider: 2,
};

export const addPolicy = (
  state = INITIAL_STATE,
  { id, owner = "", policyOwnership = [] }
) => ({
  ...state,
  policies: [
    ...state.policies,
    getInitialPolicy(
      {
        id,
        owner,
        policyOwnership,
      },
      state.noOfProductsToCondider
    ),
  ],
});

export const deletePolicy = (state = INITIAL_STATE, { index = -1 }) => {
  return {
    ...state,
    policies: state.policies.filter((s, idx) => idx !== index),
  };
};

export const editPolicy = (
  state = INITIAL_STATE,
  { index = -1, values = {} }
) => {
  return {
    ...state,
    policies: state.policies.map((s, idx) => {
      if (idx === index) {
        return { ...s, ...values };
      }
      return s;
    }),
  };
};

export const editProductToConsider = (
  state = INITIAL_STATE,
  { index = -1, productToConsiderIdx = -1, value = "" }
) => {
  return {
    ...state,
    policies: state.policies.map((s, idx) => {
      if (idx === index) {
        return {
          ...s,
          productsToConsider: [
            ...s.productsToConsider.slice(0, productToConsiderIdx),
            value,
            ...s.productsToConsider.slice(productToConsiderIdx + 1),
          ],
        };
      }
      return s;
    }),
  };
};

export const hydrate = (state = INITIAL_STATE, { policies = [] }) => ({
  ...state,
  policies:
    policies.length > 3
      ? policies
      : [...policies, ...INITIAL_STATE.policies].slice(0, 3),
  noOfProductsToCondider: Math.max(
    policies.length > 0 ? policies[0].productsToConsider.length : 2,
    2
  ),
});

export const addProductToConsider = (state = INITIAL_STATE) => ({
  ...state,
  noOfProductsToCondider: state.noOfProductsToCondider + 1,
  policies: state.policies.map(policy => ({
    ...policy,
    productsToConsider: [...policy.productsToConsider, ""],
  })),
});

export const removeProductToConsider = (state = INITIAL_STATE, { index }) => ({
  ...state,
  noOfProductsToCondider: state.noOfProductsToCondider - 1,
  policies: state.policies.map(policy => ({
    ...policy,
    productsToConsider: [
      ...policy.productsToConsider.slice(0, index),
      ...policy.productsToConsider.slice(index + 1),
    ],
  })),
});

export const HANDLERS = {
  [Types.ADD_POLICY]: addPolicy,
  [Types.DELETE_POLICY]: deletePolicy,
  [Types.EDIT_POLICY]: editPolicy,
  [Types.HYDRATE]: hydrate,
  [Types.ADD_PRODUCT_TO_CONSIDER]: addProductToConsider,
  [Types.EDIT_PRODUCT_TO_CONSIDER]: editProductToConsider,
  [Types.REMOVE_PRODUCT_TO_CONSIDER]: removeProductToConsider,
};

export default createReducer(INITIAL_STATE, HANDLERS);
