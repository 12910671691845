import { createReducer } from "reduxsauce";
import { Types } from "../../actions/modals";

// the initial state of this reducer
export const INITIAL_STATE = {
  previewDrawer: {
    open: false,
    file: null,
    loading: false,
    emails: [],
  },
  commentsDrawer: {
    open: false,
    entityType: "",
    entityId: "",
    key: "__root",
    title: "",
    comments: [],
    summary: null,
    stakeholders: [],
    readOnly: false,
    editCommentId: "",
    replyToCommentId: "",
    replyToCommentType: "",
  },
  commentsSummaryDrawer: {
    open: false,
  },
  commentsTranscriptDrawer: {
    open: false,
  },
  currentPositionDrawer: {
    open: false,
    editable: false,
    pulloutData: null,
    dataSection: "",
    tabSection: "section1",
    canScrollTab: false,
    validations: [],
    totalIncome: 0,
    totalExpenditures: 0,
    totalLiabilities: 0,
    totalLifeStyleAssets: 0,
    totalInvestments: 0,
    sectionsChanged: [],
  },
  digitalRequestDrawer: {
    open: false,
    advisors: [],
    selectedAdvisor: "",
  },
  settingsDrawer: {
    open: false,
    activeKey: "email",
    userSettings: null,
    user: null,
  },
  currentInvestmentDrawer: {
    open: false,
    portfolioType: null,
    portfolioId: null,
    productType: null,
    operationType: "new",
    refetch: false,
    noOfCards: 1,
    activeTab: "fund",
    dataRefetch: false,
  },
  extraCurrentInvestmentDrawers: {
    portfolioId: null,
    portfolioType: null,
    detailsDrawer: {
      open: false,
      apircode: null,
    },
    saveDrawer: {
      open: false,
      productDescription: "",
      productId: null,
    },
  },
  assignRequestDrawer: {
    open: false,
    paraplanners: [],
    selectedParaplanner: "",
  },
  holdDrawer: {
    holdReasons: {},
    open: false,
    selectedReasons: [],
  },
  approveDrawer: {
    open: false,
  },
  completeDrawer: {
    open: false,
  },
  pointsDrawer: {
    open: false,
    drawerFor: "",
  },
  strategiesSearchDrawer: {
    open: false,
    frequentStrategies: [],
  },
  pricingDrawer: {
    open: false,
    soaId: null,
  },
};

export const setModalValues = (state = INITIAL_STATE, { key, values }) => {
  return {
    ...state,
    [key]: {
      ...state[key],
      ...values,
    },
  };
};

export const HANDLERS = {
  [Types.SET_MODAL_VALUES]: setModalValues,
};

export default createReducer(INITIAL_STATE, HANDLERS);
