import { createActions } from "reduxsauce";

import { REDUX_KEY } from "../../../utils/constants";

export const { Types, Creators } = createActions(
  {
    setAutoCreate: {
      payload: {},
    },
    setFsoaStrategies: {
      payload: {},
    },
    setAlerts: {
      payload: {},
    },
    addStrategy: {
      strategyKey: "",
      key: "",
      dropdownValue: "",
      owner: "",
      parameters: {},
    },
    deleteStrategy: {
      id: null,
    },
    duplicateStrategy: {
      id: null,
    },
    editStrategy: {
      id: null,
      key: "",
      value: null,
    },
    editStrategyParameters: {
      id: null,
      key: "",
      value: null,
    },
    editStrategyMetadata: {
      id: null,
      key: "",
      value: null,
    },
    updateAllStrategies: {
      payload: [],
    },
    reorderStrategies: {
      startIndex: null,
      endIndex: null,
    },
    clearXplanDropdowns: null,
  },
  { prefix: `${REDUX_KEY.RECOMMENDED_STRATEGIES}_` }
);
