import React from "react";

const CompleteIcon = () => {
  return (
    <svg
      width="18px"
      height="22px"
      viewBox="0 0 18 22"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Complete</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="P13"
          transform="translate(-1302.000000, -72.000000)"
          fill="#FFFFFF"
        >
          <g
            id="Organization-/-File-/-task-/-24"
            transform="translate(1299.000000, 71.000000)"
          >
            <path
              d="M10.5,15.135 L7.8075,12.4425 L6.75,13.5 L10.5,17.25 L17.25,10.5 L16.1925,9.435 L10.5,15.135 Z M18.75,3.75 L16.5,3.75 L16.5,3 C16.5,2.17157288 15.8284271,1.5 15,1.5 L9,1.5 C8.17157288,1.5 7.5,2.17157288 7.5,3 L7.5,3.75 L5.25,3.75 C4.42157288,3.75 3.75,4.42157288 3.75,5.25 L3.75,21 C3.75,21.8284271 4.42157288,22.5 5.25,22.5 L18.75,22.5 C19.5784271,22.5 20.25,21.8284271 20.25,21 L20.25,5.25 C20.25,4.42157288 19.5784271,3.75 18.75,3.75 Z M9,3 L15,3 L15,6 L9,6 L9,3 Z M18.75,21 L5.25,21 L5.25,5.25 L7.5,5.25 L7.5,7.5 L16.5,7.5 L16.5,5.25 L18.75,5.25 L18.75,21 Z"
              id="Fill"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CompleteIcon;
