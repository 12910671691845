import { notification } from "antd";
import { createReducer } from "reduxsauce";
import { v4 as uuid } from "uuid";
import { splitTextByFields } from "../../../utils/common";
import { Types } from "../../actions/recommendedStrategies";
import PesTokenizer from "@planpod/pp-lib-pes-parser/dist/tokens/PesTokenizer";

// the initial state of this reducer
export const INITIAL_STATE = {
  autoCreate: null,
  fsoaStrategies: null,
  alerts: null,
  strategies: [],
  strategiesData: [],
};

const getInitialStategy = ({
  strategyKey,
  key,
  dropdownValue,
  owner,
  parameters,
}) => ({
  id: uuid(),
  strategyKey,
  key,
  dropdownValue,
  owner,
  reasonForRecommendation: "",
  alternateStrategies: "",
  parameters,
  invResearchId: "",
  requestMetadata: {
    isPinned: false,
  },
});

export const setAutoCreate = (state = INITIAL_STATE, { payload }) => {
  return {
    ...state,
    autoCreate: payload,
  };
};

export const setFsoaStrategies = (state = INITIAL_STATE, { payload }) => {
  return {
    ...state,
    fsoaStrategies: payload,
  };
};

export const setAlerts = (state = INITIAL_STATE, { payload }) => {
  return {
    ...state,
    alerts: payload,
  };
};

export const updateAllStrategies = (state = INITIAL_STATE, { payload }) => {
  return {
    ...state,
    strategies: payload,
  };
};

export const addStrategy = (
  state = INITIAL_STATE,
  { strategyKey, key, dropdownValue, owner, parameters }
) => {
  if (key.includes("customstrategy")) {
    notification.info({
      message: "Delivery update",
      description:
        "Your pricing and completion date may change depending on the custom strategy provided",
    });
  }

  return {
    ...state,
    strategies: [
      ...state.strategies,
      getInitialStategy({ strategyKey, key, dropdownValue, owner, parameters }),
    ],
  };
};

export const deleteStrategy = (state = INITIAL_STATE, { id }) => {
  return {
    ...state,
    strategies: state.strategies.filter(s => s.id !== id),
  };
};

export const duplicateStrategy = (state = INITIAL_STATE, { id }) => {
  const index = state.strategies.findIndex(s => s.id === id);

  return {
    ...state,
    strategies: [
      ...state.strategies.slice(0, index + 1),
      {
        ...state.strategies[index],
        id: uuid(),
        requestMetadata: {
          isPinned: false,
        },
        owner: null,
      },
      ...state.strategies.slice(index + 1),
    ],
  };
};

export const editStrategy = (state = INITIAL_STATE, { id, key, value }) => {
  return {
    ...state,
    strategies: state.strategies.map(s => {
      if (s.id !== id) return s;

      return {
        ...s,
        [key]: value,
      };
    }),
  };
};

export const editStrategyParameters = (
  state = INITIAL_STATE,
  { id, key, value }
) => {
  return {
    ...state,
    strategies: state.strategies.map(s => {
      if (s.id !== id) return s;

      return {
        ...s,
        parameters: {
          ...s.parameters,
          [key]: value,
        },
      };
    }),
  };
};

export const editStrategyMetadata = (
  state = INITIAL_STATE,
  { id, key, value }
) => {
  return {
    ...state,
    strategies: state.strategies.map(s => {
      if (s.id !== id) return s;

      return {
        ...s,
        requestMetadata: {
          ...s.requestMetadata,
          [key]: value,
        },
      };
    }),
  };
};

export const reorderStrategies = (
  state = INITIAL_STATE,
  { startIndex, endIndex }
) => {
  const result = Array.from(state.strategies);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return {
    ...state,
    strategies: result,
  };
};

export const clearXplanDropdowns = (state = INITIAL_STATE) => {
  const cleared = state.strategies.map(s => {
    const splitedArr = splitTextByFields(s.dropdownValue);
    const parser = new PesTokenizer(s.dropdownValue);
    const pes = parser.tokenize()
    const xplanFields = pes
      .filter(item => item.type.startsWith("inv"))
      .map(value => {
        const key = `var${value.id}`
        return key;
      });

    for (let i = 0; i < xplanFields.length; i++) {
      s.parameters[xplanFields[i]] = null;
    }
    s.invResearchId = "";

    return s;
  });
  return {
    ...state,
    strategies: cleared,
  };
};

export const HANDLERS = {
  [Types.SET_AUTO_CREATE]: setAutoCreate,
  [Types.SET_FSOA_STRATEGIES]: setFsoaStrategies,
  [Types.SET_ALERTS]: setAlerts,
  [Types.UPDATE_ALL_STRATEGIES]: updateAllStrategies,
  [Types.ADD_STRATEGY]: addStrategy,
  [Types.DELETE_STRATEGY]: deleteStrategy,
  [Types.EDIT_STRATEGY]: editStrategy,
  [Types.EDIT_STRATEGY_PARAMETERS]: editStrategyParameters,
  [Types.REORDER_STRATEGIES]: reorderStrategies,
  [Types.CLEAR_XPLAN_DROPDOWNS]: clearXplanDropdowns,
  [Types.EDIT_STRATEGY_METADATA]: editStrategyMetadata,
  [Types.DUPLICATE_STRATEGY]: duplicateStrategy,
};

export default createReducer(INITIAL_STATE, HANDLERS);
