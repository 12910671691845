import { v4 as uuid } from "uuid";
import { createReducer } from "reduxsauce";
import { Types } from "../../actions/investmentTable";

const getInitialPlatform = ({
  id,
  targetProduct = "",
  owner = "",
  recomendationType = "",
  autoRebalance = "",
  portfolioType: { selected = "" } = {},
  replacement: { requirement = "" } = {},
}) => ({
  id: id || uuid(),
  targetProduct,
  owner,
  recomendationType,
  portfolioType: {
    selected: selected || "",
    otherName: "",
    customFiles: [],
  },
  autoRebalance,
  customNotes: "",
  replacement: {
    requirement: requirement || "",
    alternativePlatforms: "",
    alternativePortfolio: [],
    customRequirement: "",
  },
});

export const INITIAL_STATE = {
  platforms: [
    getInitialPlatform({}),
    getInitialPlatform({}),
    getInitialPlatform({}),
  ],
  investmentProducts: {},
};

export const addPlatform = (
  state = INITIAL_STATE,
  {
    id,
    targetProduct = "",
    owner = "",
    recomendationType = "",
    portfolioType: { selected = "" } = {},
    replacement: { requirement = "" } = {},
  }
) => ({
  ...state,
  platforms: [
    ...state.platforms,
    getInitialPlatform({
      id,
      targetProduct,
      owner,
      recomendationType,
      portfolioType: { selected },
      replacement: { requirement: requirement || "" },
      autoRebalance: "n/a",
    }),
  ],
});

export const editPlatformAtIndex = (
  state = INITIAL_STATE,
  {
    index,
    id,
    targetProduct = "",
    owner = "",
    recomendationType = "",
    portfolioType: { selected = "" } = {},
    replacement: { requirement = "" } = {},
  }
) => ({
  ...state,
  platforms: state.platforms.map((s, idx) => {
    if (idx === index) {
      return getInitialPlatform({
        id,
        targetProduct,
        owner,
        recomendationType,
        portfolioType: { selected },
        replacement: { requirement: requirement || "" },
        autoRebalance: "n/a",
      });
    }
    return s;
  }),
});

export const deletePlatform = (state = INITIAL_STATE, { index = -1 }) => {
  return {
    ...state,
    platforms: state.platforms.filter((s, idx) => idx !== index),
  };
};

export const editPlatform = (
  state = INITIAL_STATE,
  { index = -1, values = {} }
) => {
  return {
    ...state,
    platforms: state.platforms.map((s, idx) => {
      if (idx === index) {
        return { ...s, ...values };
      }
      return s;
    }),
  };
};

export const hydrate = (state = INITIAL_STATE, { platforms = [] }) => ({
  ...state,
  platforms:
    platforms.length > 3
      ? platforms
      : [...platforms, ...INITIAL_STATE.platforms].slice(0, 3),
});

export const setInvestmentProducts = (
  state = INITIAL_STATE,
  { products = [] }
) => {
  return {
    ...state,
    investmentProducts: products,
  };
};

export const HANDLERS = {
  [Types.ADD_PLATFORM]: addPlatform,
  [Types.EDIT_PLATFORM_AT_INDEX]: editPlatformAtIndex,
  [Types.DELETE_PLATFORM]: deletePlatform,
  [Types.EDIT_PLATFORM]: editPlatform,
  [Types.HYDRATE]: hydrate,
  [Types.SET_INVESTMENT_PRODUCTS]: setInvestmentProducts,
};

export default createReducer(INITIAL_STATE, HANDLERS);
