import { createReducer } from "reduxsauce";
import { Types } from "../../actions/basicDetails";

// the initial state of this reducer
export const INITIAL_STATE = {
  soaRequestDetails: {
    appointmentDate: null,
    serviceOffering: localStorage.getItem("serviceOffering") || "basic",
    serviceOfferingAddOns: [],
    updateFactFindWizard: false,
    serviceRequestWriting: false,
    complianceChecklist: false,
  },
  client: {
    //
    firstName: "",
    lastName: "",
    // xplanId: "",
  },
  partner: {
    //
    firstName: "",
    lastName: "",
    // xplanId: "",
  },
  smsf: null,
  trust: null,
  company: null,
  uiOptions: {},
  practiceServices: {},
};

export const updateBasicDetails = (
  state = INITIAL_STATE,
  { soaRequestDetails, client, partner, smsf, trust, company }
) => {
  localStorage.setItem("serviceOffering", soaRequestDetails.serviceOffering);

  return {
    ...state,
    soaRequestDetails: soaRequestDetails || state.soaRequestDetails,
    client: client || state.client,
    partner: partner || state.partner,
    smsf: smsf === undefined ? state.smsf : smsf || null,
    trust: trust === undefined ? state.trust : trust || null,
    company: company === undefined ? state.company : company || null,
  };
};

export const updateClientPartner = (
  state = INITIAL_STATE,
  { client, partner }
) => {
  return {
    ...state,
    client: client || state.client,
    partner: partner || state.partner,
  };
};

export const updateServiceOffering = (
  state = INITIAL_STATE,
  { serviceOffering }
) => {
  localStorage.setItem("serviceOffering", serviceOffering);

  return {
    ...state,
    soaRequestDetails: {
      ...state.soaRequestDetails,
      serviceOffering,
    },
  };
};

export const updateUiOptions = (state = INITIAL_STATE, { uiOptions }) => {
  return {
    ...state,
    uiOptions,
  };
};

export const updatePracticeServices = (
  state = INITIAL_STATE,
  { practiceServices }
) => {
  return {
    ...state,
    practiceServices,
  };
};

export const HANDLERS = {
  [Types.UPDATE_BASIC_DETAILS]: updateBasicDetails,
  [Types.UPDATE_CLIENT_PARTNER]: updateClientPartner,
  [Types.UPDATE_SERVICE_OFFERING]: updateServiceOffering,
  [Types.UPDATE_UI_OPTIONS]: updateUiOptions,
  [Types.UPDATE_PRACTICE_SERVICES]: updatePracticeServices,
};

export default createReducer(INITIAL_STATE, HANDLERS);
