import { useDispatch, useSelector } from "react-redux";
import { Creators } from "../../actions/modals";
import { getModal } from "../../selectors/modals";

export const useSetModalValues = () => {
  const dispatch = useDispatch();

  return (key, values) => {
    dispatch(
      Creators.setModalValues({
        key,
        values,
      })
    );
  };
};

export const useGetModalValues = key => {
  const values = useSelector(getModal(key));
  return values;
};
