import { v4 as uuid } from "uuid";
import { createReducer } from "reduxsauce";
import { Types } from "../../actions/investmentResearch";

const getInitialProduct = (
  {
    id,
    targetProduct = "",
    owner = "",
    recomendationType = "",
    portfolioType: { selected = "" } = {},
  },
  noOfPlatformsToConsider
) => {
  let product = {
    id: id || uuid(),
    targetProduct,
    owner,
    recomendationType,
    valueOfNewPortfolio: "",
    portfolioType: {
      selected: selected || "",
      otherName: "",
      customFiles: [],
    },
    additionalComments: "",
    platformsToConsider: new Array(noOfPlatformsToConsider).fill(""),
  };

  return product;
};

export const INITIAL_STATE = {
  products: [
    getInitialProduct({}, 2),
    getInitialProduct({}, 2),
    getInitialProduct({}, 2),
  ],
  noOfproductToCondider: 2,
};

export const addProduct = (
  state = INITIAL_STATE,
  {
    id,
    targetProduct = "",
    owner = "",
    recomendationType = "",
    portfolioType: { selected = "" } = {},
  }
) => ({
  ...state,
  products: [
    ...state.products,
    getInitialProduct(
      {
        id,
        targetProduct,
        owner,
        recomendationType,
        portfolioType: { selected },
      },
      state.noOfproductToCondider
    ),
  ],
});

export const editProductAtIndex = (
  state = INITIAL_STATE,
  {
    index,
    id,
    targetProduct = "",
    owner = "",
    recomendationType = "",
    portfolioType: { selected = "" } = {},
  }
) => ({
  ...state,
  products: state.products.map((s, idx) => {
    if (idx === index) {
      return getInitialProduct(
        {
          id,
          targetProduct,
          owner,
          recomendationType,
          portfolioType: { selected },
        },
        state.noOfproductToCondider
      );
    }
    return s;
  }),
});

export const deleteProduct = (state = INITIAL_STATE, { index = -1 }) => {
  return {
    ...state,
    products: state.products.filter((s, idx) => idx !== index),
  };
};

export const editProduct = (
  state = INITIAL_STATE,
  { index = -1, values = {} }
) => {
  return {
    ...state,
    products: state.products.map((s, idx) => {
      if (idx === index) {
        return { ...s, ...values };
      }
      return s;
    }),
  };
};

export const editPlatformToConsider = (
  state = INITIAL_STATE,
  { index = -1, platformToConsiderIdx = -1, value = "" }
) => {
  return {
    ...state,
    products: state.products.map((s, idx) => {
      if (idx === index) {
        return {
          ...s,
          platformsToConsider: [
            ...s.platformsToConsider.slice(0, platformToConsiderIdx),
            value,
            ...s.platformsToConsider.slice(platformToConsiderIdx + 1),
          ],
        };
      }
      return s;
    }),
  };
};

export const hydrate = (state = INITIAL_STATE, { products = [] }) => ({
  ...state,
  products:
    products.length > 3
      ? products
      : [...products, ...INITIAL_STATE.products].slice(0, 3),
  noOfproductToCondider: Math.max(
    products.length > 0 ? products[0].platformsToConsider.length : 2,
    2
  ),
});

export const addPlatformToConsider = (state = INITIAL_STATE) => ({
  ...state,
  noOfproductToCondider: state.noOfproductToCondider + 1,
  products: state.products.map(product => ({
    ...product,
    platformsToConsider: [...product.platformsToConsider, ""],
  })),
});

export const HANDLERS = {
  [Types.ADD_PRODUCT]: addProduct,
  [Types.EDIT_PRODUCT_AT_INDEX]: editProductAtIndex,
  [Types.DELETE_PRODUCT]: deleteProduct,
  [Types.EDIT_PRODUCT]: editProduct,
  [Types.HYDRATE]: hydrate,
  [Types.ADD_PLATFORM_TO_CONSIDER]: addPlatformToConsider,
  [Types.EDIT_PLATFORM_TO_CONSIDER]: editPlatformToConsider,
};

export default createReducer(INITIAL_STATE, HANDLERS);
