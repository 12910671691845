import { createActions } from "reduxsauce";

import { REDUX_KEY } from "../../../utils/constants";

export const { Types, Creators } = createActions(
  {
    addPolicy: {
      id: null,
      owner: "",
      policyOwnership: [],
    },
    deletePolicy: {
      index: -1,
    },
    editPolicy: {
      index: -1,
      values: {},
    },
    editProductToConsider: {
      index: -1,
      productToConsiderIdx: -1,
      value: "",
    },
    removeProductToConsider: {
      index: -1,
    },
    hydrate: {
      policies: [],
    },
    addProductToConsider: null,
  },
  { prefix: `${REDUX_KEY.INSURANCE_RESEARCH}_` }
);
