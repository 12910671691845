import Keycloak from "keycloak-js";
import { getUrl } from "./common";

const getRealmFromWindow = () => {
  let pathname = window.location.pathname;
  return pathname.split("/")[1].toLowerCase();
};

// TODO: CHANGES REQUIRED
let initOptions = {
  url: getUrl(),
  realm: getRealmFromWindow(),
  clientId: "dsrf-app",
};

let keycloak = new Keycloak(initOptions);

export default keycloak;
