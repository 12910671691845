import React from "react";
import Icon, {
  FileOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  FileWordOutlined,
  FileTextOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import { Badge } from "antd";
import AddCommentIcon from "../../components/Icons/AddCommentIcon";
import ReadCommentIcon from "../../components/Icons/ReadCommentIcon";
import { tabEntityTypes } from "../../components/WithComments/constants";

export const getEntity = (entities = [], entityType) => {
  if (!entities) return [];

  return entities.find(e => e.entityType === entityType);
};

export const getTabSummary = (entities = []) => {
  if (!entities)
    return { unreadTabThreads: [], unreadTabComments: 0, readTabThreads: [] };
  let unreadTabThreads = [];
  let readTabThreads = [];
  let unreadTabComments = 0;

  entities.forEach(e => {
    if (Object.values(tabEntityTypes).includes(e.entityType)) {
      if (e.unreadComments > 0) {
        unreadTabComments += e.unreadComments;
        e.threads.forEach(t => {
          if (t.unreadComments > 0) {
            unreadTabThreads.push(t);
          } else {
            readTabThreads.push(t);
          }
        });
      } else {
        readTabThreads.push(...e.threads);
      }
    }
  });

  return {
    unreadTabThreads: unreadTabThreads.filter(thread => thread.totalComments),
    readTabThreads: readTabThreads.filter(thread => thread.totalComments),
    unreadTabComments,
  };
};

export const getSingleThread = entity => {
  if (!entity) return false;
  const unreadThreads = entity.threads
    ? entity.threads.filter(e => e.unreadComments !== 0)
    : [];
  if (unreadThreads.length === 1) {
    return unreadThreads[0];
  }
  return false;
};

export const getThreadByKey = (threads = [], key) => {
  if (!threads) return [];

  return threads.find(t => t.key === key);
};

export const getThreadById = (threads = [], id) => {
  if (!threads) return [];

  return threads.find(t => t.entityId === id);
};

export const getThread = (threads = [], id, key = "__root") => {
  if (!threads) return [];

  return threads.find(t => t.entityId === id && t.key === key);
};

export const getThreadHasUpApprovedChanges = thread => {
  if (
    !thread ||
    thread?.totalComments === 0 ||
    thread?.totalRequestEditedComments === 0
  ) {
    return false;
  }

  let hasUnApprovedChanges = false;

  for (let i = 0; i < thread.comments.length; i++) {
    const comment = thread.comments[i];
    const requestEdited =
      comment?.requestEdited && typeof comment.editApproved !== "boolean";
    if (requestEdited) {
      hasUnApprovedChanges = true;
      break;
    }
  }

  return hasUnApprovedChanges;
};

export const getSummaryHasUpApprovedChanges = summary => {
  if (
    !summary ||
    summary.attributes?.totalComments === 0 ||
    summary.attributes?.totalRequestEditedComments === 0
  ) {
    return false;
  }

  let unApprovedThreads = 0;
  let unApprovedThread;

  let entities = [...summary.attributes.entities];

  for (let i = 0; i < entities.length; i++) {
    const entity = entities[i];
    if (
      entity?.totalComments === 0 ||
      entity?.totalRequestEditedComments === 0
    ) {
      continue;
    }

    const threads = [...entity.threads];

    for (let i = 0; i < threads.length; i++) {
      const thread = threads[i];

      if (getThreadHasUpApprovedChanges(thread)) {
        if (unApprovedThread === undefined) {
          unApprovedThread = thread;
        } else {
          unApprovedThread = null;
        }
        unApprovedThreads += 1;

        break;
      }
    }
  }

  if (unApprovedThread) {
    return unApprovedThread.entityId;
  }

  return unApprovedThreads;
};

export const getCommentIcon = (entity, onClick = null, disabled) => {
  if (disabled) {
    return (
      <Icon
        style={{ cursor: "not-allowed" }}
        component={ReadCommentIcon}
        className="disabled-icon"
      />
    );
  }
  if (!entity || entity.totalComments === 0) {
    return (
      <Icon
        style={{ cursor: onClick ? "pointer" : "default" }}
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
        component={AddCommentIcon}
      />
    );
  }

  if (entity.unreadComments > 0) {
    return (
      <Badge size="small" count={entity.unreadComments} className="is-unread">
        <Icon
          style={{ cursor: onClick ? "pointer" : "default" }}
          onClick={() => {
            if (onClick) {
              onClick();
            }
          }}
          component={ReadCommentIcon}
        />
      </Badge>
    );
  }

  return (
    <Badge size="small" className="grey-badge" count={entity.totalComments}>
      <Icon
        style={{ cursor: onClick ? "pointer" : "default", fill: "#878D96" }}
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
        component={ReadCommentIcon}
      />
    </Badge>
  );
};

const createSummary = () => ({
  attributes: [],
  totalComments: 0,
  unreadComments: 0,
});

const createEntity = entityType => ({
  entityType,
  threads: [],
  totalComments: 0,
  unreadComments: 0,
});

const createThread = (entityId, key) => ({
  entityId,
  key,
  totalComments: 0,
  unreadComments: 0,
});

export const markAsRead = (summary, entityType, entityId, key) => {
  if (!summary) {
    summary = createSummary();
  }

  let entities = [...summary.attributes.entities];
  let entity = getEntity(entities, entityType);
  if (!entity) {
    entity = createEntity(entityType);
    entities = [...entities, entity];
  }

  let threads = [...entity.threads];
  let thread = getThread(entity.threads, entityId, key);

  if (!thread || !thread.unreadComments) {
    return summary;
  }

  const entityIdx = entities.findIndex(e => e.entityType === entityType);
  const threadIdx = threads.findIndex(
    t => t.entityId === entityId && t.key === key
  );

  threads.splice(threadIdx, 1, {
    ...thread,
    unreadComments: 0,
    comments: thread.comments || [],
  });
  entities.splice(entityIdx, 1, {
    ...entity,
    unreadComments: entity.unreadComments - thread.unreadComments,
    threads: [...threads],
  });

  return {
    ...summary,
    attributes: {
      ...summary.attributes,
      unreadComments: summary.attributes.unreadComments - thread.unreadComments,
      entities: [...entities],
    },
  };
};

export const incrementCommentCount = (
  summary,
  entityType,
  entityId,
  key,
  comments = []
) => {
  if (!summary) {
    summary = createSummary();
  }

  let entities = [...summary.attributes.entities];
  let entity = getEntity(entities, entityType);
  if (!entity) {
    entity = createEntity(entityType);
    entities = [...entities, entity];
  }

  let threads = [...entity.threads];
  let thread = getThread(entity.threads, entityId, key);

  if (!thread) {
    thread = createThread(entityId, key);
    threads = [...threads, thread];
  }

  const entityIdx = entities.findIndex(e => e.entityType === entityType);
  const threadIdx = threads.findIndex(
    t => t.entityId === entityId && t.key === key
  );

  threads.splice(threadIdx, 1, {
    ...thread,
    totalComments: thread.totalComments + 1,
    comments,
  });
  entities.splice(entityIdx, 1, {
    ...entity,
    totalComments: entity.totalComments + 1,
    threads: [...threads],
  });

  return {
    ...summary,
    attributes: {
      ...summary.attributes,
      totalComments: summary.attributes.totalComments + 1,
      entities: [...entities],
    },
  };
};

export const decrementCommentCount = (
  summary,
  entityType,
  entityId,
  key,
  comments = []
) => {
  if (!summary) {
    summary = createSummary();
  }

  let entities = [...summary.attributes.entities];
  let entity = getEntity(entities, entityType);
  if (!entity) {
    entity = createEntity(entityType);
    entities = [...entities, entity];
  }

  let threads = [...entity.threads];
  let thread = getThread(entity.threads, entityId, key);

  if (!thread) {
    thread = createThread(entityId, key);
    threads = [...threads, thread];
  }

  const entityIdx = entities.findIndex(e => e.entityType === entityType);
  const threadIdx = threads.findIndex(
    t => t.entityId === entityId && t.key === key
  );

  threads.splice(threadIdx, 1, {
    ...thread,
    totalComments: thread.totalComments - 1,
    comments,
  });
  entities.splice(entityIdx, 1, {
    ...entity,
    totalComments: entity.totalComments - 1,
    threads: [...threads],
  });

  return {
    ...summary,
    attributes: {
      ...summary.attributes,
      totalComments: summary.attributes.totalComments - 1,
      entities: [...entities],
    },
  };
};

export const updateComments = (
  summary,
  entityType,
  entityId,
  key,
  comments = []
) => {
  if (!summary) {
    summary = createSummary();
  }

  let entities = [...summary.attributes.entities];
  let entity = getEntity(entities, entityType);
  if (!entity) {
    entity = createEntity(entityType);
    entities = [...entities, entity];
  }

  let threads = [...entity.threads];
  let thread = getThread(entity.threads, entityId, key);

  if (!thread) {
    thread = createThread(entityId, key);
    threads = [...threads, thread];
  }

  const entityIdx = entities.findIndex(e => e.entityType === entityType);
  const threadIdx = threads.findIndex(
    t => t.entityId === entityId && t.key === key
  );

  threads.splice(threadIdx, 1, {
    ...thread,
    comments,
  });
  entities.splice(entityIdx, 1, {
    ...entity,
    threads: [...threads],
  });

  return {
    ...summary,
    attributes: {
      ...summary.attributes,
      entities: [...entities],
    },
  };
};

export const ACCEPTED_FILE_FORMATS = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel.sheet.macroEnabled.12",
  "application/pdf",
  "text/plain",
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/bmp",
];

export const getFileIcon = extension => {
  switch (extension) {
    case ".pdf":
      return <FilePdfOutlined />;

    case ".doc":
    case ".docx":
      return <FileWordOutlined />;

    case ".xls":
    case ".xlsx":
      return <FileExcelOutlined />;

    case ".jpg":
    case ".jpeg":
    case ".png":
    case ".bmp":
      return <FileImageOutlined />;

    case ".txt":
      return <FileTextOutlined />;

    default:
      return <FileOutlined />;
  }
};

export const getFileType = extension => {
  switch (extension) {
    case ".pdf":
      return "application/pdf";
    case ".doc":
      return "application/msword";
    case ".docx":
      return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    case ".xls":
      return "application/vnd.ms-excel";
    case ".xlsx":
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    case ".jpg":
      return "image/jpg";
    case ".jpeg":
      return "image/jpeg";
    case ".png":
      return "image/png";
    case ".bmp":
      return "image/bmp";
    case ".txt":
      return "text/plain";
    default:
      return "";
  }
};

export const checkIsImage = extension => {
  switch (extension) {
    case ".jpg":
    case ".jpeg":
    case ".png":
    case ".bmp":
      return true;

    default:
      return false;
  }
};
