import { message } from "antd";
import { useContext } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { AuthContext } from "../../Auth";
import { getSoaById } from "../../axios/login";
import { useSetModalValues } from "../../redux/hooks/modals";
import {
  useSetAdvisorId,
  useSetCommentsAllowed,
  useSetCreatorId,
  useSetEditingAllowed,
  useSetFeesVersion,
  useSetFirstSubmissionComplete,
  useSetPinningAllowed,
  useSetPricingScheduleAvailable,
  useSetProductionAllowed,
  useSetSoaVersion,
  useSetXplanProductListVersion
} from "../../redux/hooks/request";
import ApiUtils from "../../utils/apiUtils";
import { getRealm, getSoaId, setSoaId } from "../../utils/common";
import { downloadFile } from "../paraplanner/soaGeneration";

const deleteDraftDR = async soaId => {
  const config = {
    method: "DELETE",
    url: `/soa/${soaId}`,
  };
  return ApiUtils.DSRF_HTTPS(config);
};

const useDeleteDraftDR = () => {
  const { mutateAsync } = useMutation(deleteDraftDR, {
    onSuccess: () => {
      message.success("Success", 2.5);
    },
    onError: err => {
      message.error("Something went wrong deleting the request..!!");
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
  });

  return [mutateAsync];
};

const updateStatusDR = async ({ soaId, action = "revert-submit" }) => {
  const config = {
    method: "PUT",
    url: `/soa/${soaId}?action=${action}`,
  };
  return ApiUtils.DSRF_HTTPS(config);
};

const useUpdateStatusDR = () => {
  const { mutateAsync } = useMutation(updateStatusDR, {
    onSuccess: () => {
      message.success("Success", 2.5);
    },
    onError: err => {
      message.error("Whoops - no luck! We'll look into this", 2.5);
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
  });

  return [mutateAsync];
};

const downloadSRequest = async ({ queryKey }) => {
  const { soaId, download } = queryKey[1];
  let { data } = await ApiUtils.DSRF_HTTPS.get(
    `soa/${soaId}?download=${download}`
  );
  return data;
};


const negotiateWpsUrl = async () => {
  let { data } = await ApiUtils.DSRF_HTTPS.get(
    `data/comms`
  );
  return { data };
};

const useDownloadSRequest = (soaId, download = "word") => {
  const { data, isLoading, refetch } = useQuery(
    ["download", { soaId, download }],
    downloadSRequest,
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      onError: () => {
        message.error("Whoops - no luck! We'll look into this", 2.5);
      },
    }
  );

  const downloadRequest = () => {
    let text =
      download === "pricing"
        ? "Generating Pricing Report"
        : "Generating Report";
    const hide = message.loading(text, 0);

    refetch().then(res => {
      hide();
      if (res?.data?.url) {
        message.success("Done");
        downloadFile(res.data.url, `${soaId}-report.docx`, true);
      } else
        message.error(
          "Whoops - unable to download file. Please try again later",
          2.5
        );
    });
  };

  return [downloadRequest, { data, isLoading }];
};

const getUserSettings = async () => {
  let { data } = await ApiUtils.DSRF_HTTPS.get(`/user`);
  return data;
};

const useGetUserSettings = () => {
  const setModalValues = useSetModalValues();

  useQuery("userSettings", getUserSettings, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
    onError: () => {
      message.error("Whoops - no luck! We'll look into this", 2.5);
    },
    onSuccess: data => {
      setModalValues("settingsDrawer", { userSettings: data });
    },
  });
};

const updateUserSettings = async ({ params, body }) => {
  let { data } = await ApiUtils.DSRF_HTTPS.put(`/user?settings=${params}`, {
    ...body,
  });
  return data;
};

const useUpdateUserSettings = () => {
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation(updateUserSettings, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
    onSuccess: () => {
      message.success("Settings updated", 2.5);
      queryClient.refetchQueries(["userSettings"]);
    },
    onError: err => {
      message.error("Something went wrong updating the settings..!!");
    },
  });

  return [mutateAsync];
};

const getUserPracticeName = async ({ queryKey }) => {
  const { dealerGrp, advisorId } = queryKey[1];
  let { data } = await ApiUtils.HTTPS.get(
    `/realm/user/${dealerGrp}/${advisorId}`
  );
  return data;
};

const useGetUser = (dealerGrp, advisorId, enabled = false) => {
  const setModalValues = useSetModalValues();

  const { data, isLoading } = useQuery(
    ["user", { dealerGrp, advisorId }],
    getUserPracticeName,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      enabled,
      onError: () => {
        message.error("Whoops - no luck! We'll look into this", 2.5);
      },
      onSuccess: data => {
        setModalValues("settingsDrawer", { user: data.data });
      },
    }
  );

  return { data, isLoading };
};

const updateUser = ({ dealerGrp, advisorId }) => async ({ body }) => {
  let { data } = await ApiUtils.HTTPS.patch(
    `/realm/user/${dealerGrp}/${advisorId}`,
    {
      ...body,
    }
  );
  return data;
};

const useUpdateUser = () => {
  const queryClient = useQueryClient();
  const {
    parsedToken: { sub, planpod_dealer_group },
  } = useContext(AuthContext);

  const { mutateAsync } = useMutation(
    updateUser({ dealerGrp: planpod_dealer_group, advisorId: sub }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      onSuccess: () => {
        message.success("Settings updated", 2.5);
      },
      onError: err => {
        message.error("Something went wrong updating the settings..!!");
      },
      onSettled: () => {
        queryClient.refetchQueries([planpod_dealer_group, sub]);
      },
    }
  );

  return [mutateAsync];
};

const updateCompletionDate = async ({ soaId, completionDate, sendEmail }) => {
  const config = {
    method: "PATCH",
    url: `/soa/${soaId}?action=update_completion_date${
      !sendEmail ? "&sendEmail=false" : ""
    }`,
    data: { completionDate },
  };
  return ApiUtils.DSRF_HTTPS(config);
};

const useUpdateCompletionDate = () => {
  const { mutateAsync } = useMutation(updateCompletionDate, {
    onSuccess: () => {
      message.success("Success", 2.5);
    },
    onError: err => {
      message.error("Something went wrong updating the completion date..!!");
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
  });

  return [mutateAsync];
};

const logIncompleteForm = async ({ soaId = getSoaId(), data }) => {
  if (!soaId) {
    return null;
  }

  const config = {
    method: "PUT",
    url: `/soa/${soaId}?action=incompleteForm`,
    data: data,
  };
  return ApiUtils.DSRF_HTTPS(config);
};

const useLogIncompleteForm = () => {
  const { mutate } = useMutation(logIncompleteForm, {
    onSuccess: () => {},
    onError: err => {},
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
  });

  return { mutate };
};

const useGetDR = (enabled = true) => {
  const { soaId, realm } = useParams();
  const history = useHistory();
  const setAdvisorId = useSetAdvisorId();
  const setCommentsAllowed = useSetCommentsAllowed();
  const setEditingAllowed = useSetEditingAllowed();
  const setPinningAllowed = useSetPinningAllowed();
  const setPricingScheduleAvailable = useSetPricingScheduleAvailable();
  const setCreatorId = useSetCreatorId();
  const setProductionAllowed = useSetProductionAllowed();
  const setFirstSubmissionComplete = useSetFirstSubmissionComplete();
  const setSoaVersion = useSetSoaVersion();
  const setFeesVersion = useSetFeesVersion();
  const setXplanProductListVersion = useSetXplanProductListVersion()

  const { data, refetch, isFetched, isFetching } = useQuery(
    [`/soa`, { soaId }],
    getSoaById,
    {
      enabled: false,
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      onSuccess: data => {
        setSoaId(soaId);
        setSoaVersion(data.version);
        setXplanProductListVersion(data.xplanProductListVersion);
        setFeesVersion(data.feesVersion);
        localStorage.setItem("advisorId", data.advisorId);
        setAdvisorId(data.advisorId);
        setCreatorId(data.creatorId);
        setProductionAllowed(data.productionAllowed);
        setFirstSubmissionComplete(data.firstSubmissionComplete);
        setCommentsAllowed(data.commentsAllowed);
        setEditingAllowed(data.editingAllowed);
        setPinningAllowed(data.pinningAllowed);
        setPricingScheduleAvailable(data.pricingScheduleAvailable);
      },
      onError: () => {
        localStorage.removeItem("soaId");
        localStorage.removeItem("advisorId");
        localStorage.removeItem("soaVersion");
        localStorage.removeItem("xplanProductListVersion");
        message.error("Whoops - no luck! We'll look into this", 2.5);
        history.push(`/${realm}/soa`);
      },
    }
  );

  return { data, refetch, isFetched, isFetching };
};

const getUserDetails = async ({ queryKey }) => {
  const { creatorId } = queryKey[1];
  let { data } = await ApiUtils.HTTPS.get(
    `/realm/user/${getRealm()}/${creatorId}`
  );
  return data;
};

const useGetUserDetails = (creatorId, enabled = false) => {
  const { data, isLoading, refetch, error, isFetching } = useQuery(
    ["/user-details", { creatorId }],
    getUserDetails,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      enabled,
    }
  );
  return { data, isLoading, refetch, error, isFetching };
};

export {
  useDeleteDraftDR,
  useDownloadSRequest,
  useGetUserSettings,
  useUpdateUserSettings,
  useGetUser,
  useUpdateUser,
  useUpdateStatusDR,
  useUpdateCompletionDate,
  useGetDR,
  useGetUserDetails,
  useLogIncompleteForm,
  negotiateWpsUrl
};
