import { useDispatch, useSelector } from "react-redux";
import { Creators } from "../../actions/investmentTable";
import {
  getInvestmentProducts,
  getPlatform,
  getPlatforms,
  getPlatformValue,
} from "../../selectors/investmentTable";

export const usePlatforms = () => {
  const dispatch = useDispatch();

  const platforms = useSelector(getPlatforms);

  const addPlatform = ({
    id,
    targetProduct = "",
    owner = "",
    recomendationType = "",
    portfolioType: { selected = "" } = {},
    replacement: { requirement = "" } = {},
  }) => {
    dispatch(
      Creators.addPlatform({
        id,
        targetProduct,
        owner,
        recomendationType,
        portfolioType: { selected },
        replacement: { requirement: requirement || "" },
      })
    );
  };

  const deletePlatform = index => {
    dispatch(
      Creators.deletePlatform({
        index,
      })
    );
  };

  const editPlatform = (index, values) => {
    dispatch(
      Creators.editPlatform({
        index,
        values,
      })
    );
  };

  const editPlatformAtIndex = ({
    index,
    id,
    targetProduct = "",
    owner = "",
    recomendationType = "",
    portfolioType: { selected = "" } = {},
    replacement: { requirement = "" } = {},
  }) => {
    dispatch(
      Creators.editPlatformAtIndex({
        index,
        id,
        targetProduct,
        owner,
        recomendationType,
        portfolioType: { selected },
        replacement: { requirement: requirement || "" },
      })
    );
  };

  return {
    platforms,
    addPlatform,
    deletePlatform,
    editPlatform,
    editPlatformAtIndex,
  };
};

export const useInvestmentHydrate = () => {
  const dispatch = useDispatch();

  const hydrate = ({ platforms }) => {
    dispatch(
      Creators.hydrate({
        platforms: platforms || [],
      })
    );
  };
  return hydrate;
};

export const useInvestmentProducts = () => {
  const dispatch = useDispatch();
  const products = useSelector(getInvestmentProducts);

  const setInvestmentProducts = ({ products }) => {
    dispatch(
      Creators.setInvestmentProducts({
        products: products || {},
      })
    );
  };
  return { products, setInvestmentProducts };
};

export const usePlatform = index => {
  const dispatch = useDispatch();

  const platform = useSelector(getPlatform(index));

  const editPlatform = values => {
    dispatch(
      Creators.editPlatform({
        index,
        values,
      })
    );
  };

  return {
    platform,
    editPlatform,
  };
};

export const usePlatformValue = (index, key) => {
  const dispatch = useDispatch();

  const value = useSelector(getPlatformValue(index, key));

  const editPlatform = value => {
    dispatch(
      Creators.editPlatform({
        index,
        values: { [key]: value || "" },
      })
    );
  };

  return {
    value,
    editPlatform,
  };
};
