import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Affix, Button } from "antd";
import { LoadingOutlined} from "@ant-design/icons";
import CommentsList from "./CommentsList";
import AddComment from "./AddComment";
import {
  useAcknowledgeComments,
  useAddComment,
  useGetComments,
  useDeleteComment,
  useDeleteFileAttachment,
  useEditComment,
  useRequestConfirmation,
  useRequestEditApproval,
} from "../../api/currentPosition/comments";
import { useGetModalValues, useSetModalValues } from "../../redux/hooks/modals";

import "./index.css";
import { tabEntities } from "../../components/WithComments/constants";
import MegaphoneIcon from "../../components/Icons/MegaphoneIcon";
import { useGetCommentsAllowed, useGetStatus } from "../../redux/hooks/request";
import { COMMENT, REQUEST_STATUS } from "../../utils/constants";
import { AuthContext } from "../../Auth";

import EditIcon from "../../components/Icons/EditIcon";

const GeneralMessageInfo = () => {
  return (
    <div className="general-message-info">
      <MegaphoneIcon />
      <p>You can use this message thread to communicate general queries</p>
    </div>
  );
};

const CommentsDrawer = ({openDrawer=false}) => {
  const setModalValues = useSetModalValues();
  const {
    open,
    entityType,
    entityId,
    key,
    title,
    comments,
    editCommentId,
    replyToCommentId,
    replyToCommentType,
  } = useGetModalValues("commentsDrawer");

  const commentsAllowed = useGetCommentsAllowed();
  const readOnly = !commentsAllowed;


  // CHECK ME
  // const onDrawerClose = () => {
  //   setModalValues("commentsDrawer", {
  //     open: false,
  //     entityType: "",
  //     entityId: "",
  //     key: "__root",
  //     title: "",
  //     comments: [],
  //     editCommentId: "",
  //     replyToCommentId: "",
  //     replyToCommentType: "",
  //   });
  //   setFilesPreview([]);
  // };
  const [selectedEditComment,setSelectedEditComment] = useState(null)
  const setEditCommentId = editCommentInfo => {
    // setModalValues("commentsDrawer", {
    //   editCommentId: id,
    // });
    setSelectedEditComment(editCommentInfo)
    setShowAddComment(false)
  };

  const setReplyToComment = ({ id, type }) => {
    setModalValues("commentsDrawer", {
      replyToCommentId: id,
      replyToCommentType: type,
    });
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const {
    data: commentsData,
    isFetching,
    refetch: fetchComments,
  } = useGetComments(entityType, entityId, key);


  const [addNewComment, { isLoading: isAddCommentPending }] = useAddComment({
    fetchComments: fetchComments,
  });

  const [
    deleteComment,
    { isLoading: isDeletingCommentPending },
  ] = useDeleteComment({
    fetchComments: fetchComments,
  });

  const [
    deleteFileAttachment,
    { isLoading: isDeletingFilePending },
  ] = useDeleteFileAttachment({
    fetchComments: fetchComments,
  });

  const [editComment, { isLoading: isEditCommentPending }] = useEditComment({
    fetchComments: fetchComments,
  });

  const [
    requestConfirmation,
    { isLoading: isRequestConfirmationPending },
  ] = useRequestConfirmation({
    fetchComments: fetchComments,
  });

  const [
    requestEditApproval,
    { isLoading: isRequestEditApprovalPending },
  ] = useRequestEditApproval({
    fetchComments: fetchComments,
  });

  const status = useGetStatus();

  const {
    parsedToken: {
      realm_access: { roles},
    },
  } = useContext(AuthContext);

  const disableUnreadComments =
  roles?.includes("advisor") && status === REQUEST_STATUS.inReview_reviewing;
  
  const [acknowledgeComments] = useAcknowledgeComments();

  const handleDeleteComment = commentId => {
    deleteComment({ entityType:COMMENT.ENTITY_TYPE, entityId:COMMENT.ENTITY_ID, key, commentId });
  };

  const handleDeleteFile = ({ commentId, attachementId }) => {
    deleteFileAttachment({
      entityType,
      entityId,
      key,
      commentId,
      attachementId,
    });
  };

  const handleEditComment = ({ commentId, content }) => {
    editComment({
      entityType:COMMENT.ENTITY_TYPE,
      entityId:COMMENT.ENTITY_ID,
      key,
      commentId,
      content,
    });
    setSelectedEditComment(null)
    // setEditCommentId(null);
  };

  const handleRequestConfirmation = ({ commentId }) => {
    requestConfirmation({
      entityType:COMMENT.ENTITY_TYPE,
      entityId:COMMENT.ENTITY_ID,
      key,
      commentId,
    });
  };

  const handleRequestEditApproval = ({ commentId, editApproved }) => {
    requestEditApproval({
      entityType,
      entityId,
      key,
      commentId,
      editApproved,
    });
  };

  const inputRef = useRef(null);

  useEffect(() => {
    if (entityType && entityId && key) {
      fetchComments();
      if (!disableUnreadComments) {
        acknowledgeComments({ entityType, entityId, key });
      }
    }
  }, [entityType, entityId, key, disableUnreadComments]);

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.focus();
    }
  }, [open]);

  useEffect(() => {
    if (editCommentId && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editCommentId]);

  //States declaring
  const [showAddComment, setShowAddComment] = useState(false);

  const showInfo =
  entityId === tabEntities.general.entityId &&
  commentsData &&
  comments?.length === 0;


// New way fetch comments
//Need add tabs comments latter
const { summary } = useGetModalValues("commentsDrawer");
const allComments = useMemo(
  () => {
    const cs = [];
    for (const e of summary?.attributes?.entities) {
      for (const t of e.threads) {
        cs.push(...t.comments.map(c => { c.entityType = e.entityType; return c; }))
      }
    }
    return cs;
  },
  [summary]
);

 return (
    <>
      <section className="comments-drawer">

          <div className="comments-list">
            {showInfo ? (
              <GeneralMessageInfo />
            ) : (
              <CommentsList
                comments={allComments}
                isDrawerVisible={open}
                handleDeleteComment={handleDeleteComment}
                isDeletingCommentPending={isDeletingCommentPending}
                handleDeleteFile={handleDeleteFile}
                isDeletingFilePending={isDeletingFilePending}
                setEditCommentId={setEditCommentId}
                editCommentId={editComment.commentId}
                setReplyToComment={setReplyToComment}
                handleRequestConfirmation={handleRequestConfirmation}
                handleRequestEditApproval={handleRequestEditApproval}
                setShowAddComment={setShowAddComment}
                handleEditComment={handleEditComment}
              />
            )}
            {isFetching ? (
              <LoadingOutlined style={{ margin: "0 20px" }} />
            ) : null}
          </div>
          <div className="bottom-section" style={{marginBottom:'200px'}}>
            {/* Added editCommentId to check whether it's in edit mode */}

            <div className="add-comment">
              <Affix offsetBottom={10}>
                <div style={{ backgroundColor: 'white' }}>
                  {!showAddComment && <Button
                    className="add-new-message"
                    shape="round"
                    size="large"
                    icon={<EditIcon />}
                    type="primary"
                    onClick={()=>{setShowAddComment(true)}}
                  >
                    Click to compose new message
                  </Button>}


                  {/* {showGeneralButton && commentsAllowed && (
                  )} */}
                  {showAddComment && 
                  <div style={{ position: 'absolute', bottom: '24px', width: '100%', paddingRight: '10px' }}>
                    <AddComment
                      entityType={COMMENT.ENTITY_TYPE}
                      entityId={COMMENT.ENTITY_ID}
                      readOnly={readOnly || disableUnreadComments}
                      handleEditComment={handleEditComment}
                      isEditCommentPending={isEditCommentPending}
                      isAddCommentPending={isAddCommentPending}
                      inputRef={inputRef}
                      editComment={selectedEditComment}
                      onCommentSubmit={() => { setShowAddComment(false) }}
                    />
                  </div>}
                </div>
              </Affix>
            </div>
          </div>
      </section>
    </>
  );
};

export default CommentsDrawer;
