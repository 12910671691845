import { createReducer } from "reduxsauce";
import { Types } from "../../actions/request";

// the initial state of this reducer
export const INITIAL_STATE = {
  activeTab: "",
  status: "",
  information: [],
  advisors: [],
  advisorId: "",
  commentsAllowed: false,
  editingAllowed: false,
  readOnly: true,
  creatorId: "",
  firstSubmissionComplete: false,
  soaVersion: undefined,
  isSyncing: false,
  initialData: null,
  feesVersion: undefined,
  productionAllowed: false,
  pricingScheduleAvailable: false,
  xplanProductListVersion: undefined
};

export const setCreatorId = (state = INITIAL_STATE, { creatorId }) => ({
  ...state,
  creatorId,
});

export const setProductionAllowed = (
  state = INITIAL_STATE,
  { productionAllowed }
) => ({
  ...state,
  productionAllowed,
});

export const setFirstSubmissionComplete = (
  state = INITIAL_STATE,
  { firstSubmissionComplete }
) => ({
  ...state,
  firstSubmissionComplete,
});

export const setActiveTab = (state = INITIAL_STATE, { activeTab }) => ({
  ...state,
  activeTab: activeTab || state.activeTab,
});

export const setStatus = (state = INITIAL_STATE, { status }) => ({
  ...state,
  status,
});

export const setInformation = (state = INITIAL_STATE, { information }) => ({
  ...state,
  information,
});

export const setAdvisors = (state = INITIAL_STATE, { advisors }) => ({
  ...state,
  advisors,
});

export const setAdvisorId = (state = INITIAL_STATE, { advisorId }) => ({
  ...state,
  advisorId,
});

export const setSoaVersion = (state = INITIAL_STATE, { soaVersion }) => ({
  ...state,
  soaVersion,
});

export const setXplanProductListVersion = (state = INITIAL_STATE, { xplanProductListVersion }) => ({
  ...state,
  xplanProductListVersion
})

export const setCommentsAllowed = (
  state = INITIAL_STATE,
  { commentsAllowed }
) => ({
  ...state,
  commentsAllowed,
});

export const setEditingAllowed = (
  state = INITIAL_STATE,
  { editingAllowed }
) => ({
  ...state,
  editingAllowed,
});

export const setReadOnly = (state = INITIAL_STATE, { readOnly }) => ({
  ...state,
  readOnly,
});

export const setIsSyncing = (state = INITIAL_STATE, { isSyncing }) => ({
  ...state,
  isSyncing,
});

export const setInitialData = (state = INITIAL_STATE, { initialData }) => ({
  ...state,
  initialData,
});

export const setFeesVersion = (state = INITIAL_STATE, { feesVersion }) => ({
  ...state,
  feesVersion,
});

export const setPinningAllowed = (
  state = INITIAL_STATE,
  { pinningAllowed }
) => ({
  ...state,
  pinningAllowed,
});

export const setPricingScheduleAvailable = (
  state = INITIAL_STATE,
  { pricingScheduleAvailable }
) => ({
  ...state,
  pricingScheduleAvailable,
});

export const HANDLERS = {
  [Types.SET_ACTIVE_TAB]: setActiveTab,
  [Types.SET_CREATOR_ID]: setCreatorId,
  [Types.SET_PRODUCTION_ALLOWED]: setProductionAllowed,
  [Types.SET_STATUS]: setStatus,
  [Types.SET_INFORMATION]: setInformation,
  [Types.SET_ADVISORS]: setAdvisors,
  [Types.SET_ADVISOR_ID]: setAdvisorId,
  [Types.SET_COMMENTS_ALLOWED]: setCommentsAllowed,
  [Types.SET_EDITING_ALLOWED]: setEditingAllowed,
  [Types.SET_READ_ONLY]: setReadOnly,
  [Types.SET_FIRST_SUBMISSION_COMPLETE]: setFirstSubmissionComplete,
  [Types.SET_SOA_VERSION]: setSoaVersion,
  [Types.SET_IS_SYNCING]: setIsSyncing,
  [Types.SET_INITIAL_DATA]: setInitialData,
  [Types.SET_FEES_VERSION]: setFeesVersion,
  [Types.SET_PINNING_ALLOWED]: setPinningAllowed,
  [Types.SET_PRICING_SCHEDULE_AVAILABLE]: setPricingScheduleAvailable,
  [Types.SET_XPLAN_PRODUCT_LIST_VERSION]: setXplanProductListVersion
};

export default createReducer(INITIAL_STATE, HANDLERS);
