import { createActions } from "reduxsauce";

import { REDUX_KEY } from "../../../utils/constants";

export const { Types, Creators } = createActions(
  {
    setActiveTab: {
      activeTab: "",
    },
    setStatus: {
      status: "",
    },
    setInformation: {
      information: [],
    },
    setAdvisors: {
      advisors: [],
    },
    setAdvisorId: {
      advisorId: "",
    },
    setCommentsAllowed: {
      commentsAllowed: false,
    },
    setEditingAllowed: {
      editingAllowed: false,
    },
    setReadOnly: {
      readOnly: true,
    },
    setCreatorId: {
      creatorId: "",
    },
    setProductionAllowed: {
      productionAllowed: false,
    },
    setSoaVersion: {
      soaVersion: undefined,
    },
    setFirstSubmissionComplete: {
      firstSubmissionComplete: false,
    },
    setIsSyncing: {
      isSyncing: false,
    },
    setInitialData: {
      initialData: null,
    },
    setFeesVersion: {
      feesVersion: undefined,
    },
    setPinningAllowed: {
      pinningAllowed: false,
    },
    setPricingScheduleAvailable: {
      pricingScheduleAvailable: false,
    },
    setXplanProductListVersion: {
      xplanProductListVersion: undefined
    }
  },
  { prefix: `${REDUX_KEY.REQUEST}_` }
);
