const tabEntityTypes = {
  basicDetails: "basicDetailsTab",
  strategies: "strategiesTab",
  investment: "investmentTab",
  insurance: "insuranceTab",
  investmentResearch: "investmentResearchTab",
  insuranceResearch: "insuranceResearchTab",
  ancillary: "ancillaryTab",
  general: "generalTab",
};

const tabEntityTypeTitles = {
  basicDetailsTab: "Basic Details",
  strategiesTab: "Strategies",
  investmentTab: "Investment",
  insuranceTab: "Insurance",
  investmentResearchTab: "InvestmentResearch",
  insuranceResearchTab: "InsuranceResearch",
  ancillaryTab: "Ancillary",
  generalTab: "General",
};

const tabEntities = {
  // Ancillary
  ancillaryAdvisorFees: {
    entityId: "ancillaryAdvisorFees",
    title: "Ancillary - Advisor Fees",
    tab: "Ancillary",
    entityType: tabEntityTypes.ancillary,
  },
  ancillaryFinancialPro: {
    entityId: "ancillaryFinancialPro",
    title: "Ancillary - Financial Projections",
    tab: "Ancillary",
    entityType: tabEntityTypes.ancillary,
  },
  ancillaryClientServiceAgreement: {
    entityId: "ancillaryClientServiceAgreement",
    title: "Ancillary - Client Service Agreement",
    tab: "Ancillary",
    entityType: tabEntityTypes.ancillary,
  },
  ancillaryUseTransactionAuthority: {
    entityId: "ancillaryUseTransactionAuthority",
    title: "Ancillary - Adviser Online Transaction Authority",
    tab: "Ancillary",
    entityType: tabEntityTypes.ancillary,
  },
  ancillarySoaResult: {
    entityId: "ancillarySoaResult",
    title: "Ancillary - SoA Result",
    tab: "Ancillary",
    entityType: tabEntityTypes.ancillary,
  },
  ancillaryRoaPurpose: {
    entityId: "ancillaryRoaPurpose",
    title: "Ancillary - Purpose of RoA",
    tab: "Ancillary",
    entityType: tabEntityTypes.ancillary,
  },
  ancillaryIsConsistent: {
    entityId: "ancillaryIsConsistent",
    title: "Ancillary - Basis for Advice",
    tab: "Ancillary",
    entityType: tabEntityTypes.ancillary,
  },
  ancillaryIsSignificant: {
    entityId: "ancillaryIsSignificant",
    title: "Ancillary - Nature of Advice",
    tab: "Ancillary",
    entityType: tabEntityTypes.ancillary,
  },
  ancillaryRoaFormat: {
    entityId: "ancillaryRoaFormat",
    title: "Ancillary - RoA Format",
    tab: "Ancillary",
    entityType: tabEntityTypes.ancillary,
  },
  ancillaryOverallComments: {
    entityId: "ancillaryOverallComments",
    title: "Ancillary - Overall Comments",
    tab: "Ancillary",
    entityType: tabEntityTypes.ancillary,
  },
  ancillaryDesignDistributionObligations: {
    entityId: "ancillaryDesignDistributionObligations",
    title: "Ancillary - Design and Distribution Obligations",
    tab: "Ancillary",
    entityType: tabEntityTypes.ancillary,
  },
  ancillaryUploads: {
    entityId: "ancillaryUploads",
    title: "Ancillary - Uploads",
    tab: "Ancillary",
    entityType: tabEntityTypes.ancillary,
  },
  // Basic Details
  clientBasicDetails: {
    entityId: "clientBasicDetails",
    title: "Client's Basic Details",
    tab: "Basic Details",
    entityType: tabEntityTypes.basicDetails,
  },
  partnerBasicDetails: {
    entityId: "partnerBasicDetails",
    title: "Partner's Basic Details",
    tab: "Basic Details",
    entityType: tabEntityTypes.basicDetails,
  },
  basicDetailsOtherEntities: {
    entityId: "basicDetailsOtherEntities",
    title: "Basic Details - Other Entities",
    tab: "Basic Details",
    entityType: tabEntityTypes.basicDetails,
  },
  basicDetailsIsClientNew: {
    entityId: "basicDetailsIsClientNew",
    title: "Basic Details - New or Existing Client",
    tab: "Basic Details",
    entityType: tabEntityTypes.basicDetails,
  },
  basicDetailsIsXplanAccessProvided: {
    entityId: "basicDetailsIsXplanAccessProvided",
    title: "Basic Details - Xplan Access",
    tab: "Basic Details",
    entityType: tabEntityTypes.basicDetails,
  },
  basicDetailsAppointmentDate: {
    entityId: "basicDetailsAppointmentDate",
    title: "Basic Details - Appointment Date",
    tab: "Basic Details",
    entityType: tabEntityTypes.basicDetails,
  },
  basicDetailsServiceOffering: {
    entityId: "basicDetailsServiceOffering",
    title: "Basic Details - Service Offering",
    tab: "Basic Details",
    entityType: tabEntityTypes.basicDetails,
  },
  basicDetailsAddOns: {
    entityId: "basicDetailsAddOns",
    title: "Basic Details - Add-ons",
    tab: "Basic Details",
    entityType: tabEntityTypes.basicDetails,
  },
  // Others
  insuranceResearchOverallComments: {
    entityId: "insuranceResearchOverallComments",
    title: "Insurance Research - Overall Comments",
    tab: "Insurance Research",
    entityType: tabEntityTypes.insuranceResearch,
  },
  insuranceResearch: {
    entityId: "insuranceResearch",
    title: "Insurance Research",
    tab: "Insurance Research",
    entityType: tabEntityTypes.insuranceResearch,
  },
  insuranceRecommendationOverallComments: {
    entityId: "insuranceRecommendationOverallComments",
    title: "Insurance Recommendation - Overall Comments",
    tab: "Insurance Products",
    entityType: tabEntityTypes.insurance,
  },
  insuranceRecommendation: {
    entityId: "insuranceRecommendation",
    title: "Insurance Recommendation",
    tab: "Insurance Products",
    entityType: tabEntityTypes.insurance,
  },
  insuranceRecommendationalternativeQuotes: {
    entityId: "insuranceRecommendationalternativeQuotes",
    title: "Insurance Recommendation - Alternate Quotes",
    tab: "Insurance Products",
    entityType: tabEntityTypes.insurance,
  },
  insuranceRecommendationrecommendedQuotes: {
    entityId: "insuranceRecommendationrecommendedQuotes",
    title: "Insurance Recommendation - Recommended Quotes",
    tab: "Insurance Products",
    entityType: tabEntityTypes.insurance,
  },
  investmentResearchOverallComments: {
    entityId: "investmentResearchOverallComments",
    title: "Investment Research - Overall Comments",
    tab: "Investment Research",
    entityType: tabEntityTypes.investmentResearch,
  },
  investmentResearch: {
    entityId: "investmentResearch",
    title: "Investment Research",
    tab: "Investment Research",
    entityType: tabEntityTypes.investmentResearch,
  },
  investmentRecommendationOverallComments: {
    entityId: "investmentRecommendationOverallComments",
    title: "Investment Recommendation - Overall Comments",
    tab: "Investment Products",
    entityType: tabEntityTypes.investment,
  },
  investmentRecommendation: {
    entityId: "investmentRecommendation",
    title: "Investment Recommendation",
    tab: "Investment Products",
    entityType: tabEntityTypes.investment,
  },
  strategyRecommendations: {
    entityId: "strategyRecommendations",
    title: "Strategy recommendations",
    tab: "Recommended Strategies",
    entityType: tabEntityTypes.strategies,
  },
  general: {
    entityId: "general",
    title: "General Messages",
    tab: "",
    entityType: tabEntityTypes.general,
  },
};

export { tabEntityTypes, tabEntities, tabEntityTypeTitles };
