import { createSelector } from "reselect";
import { REDUX_KEY } from "../../../utils/constants";

const reducerKey = REDUX_KEY.MODALS;

export const getModal = key =>
  createSelector(
    state => state[reducerKey][key],
    modal => modal
  );
