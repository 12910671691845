import { ApplicationInsights } from "@microsoft/applicationinsights-web";

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APPI_KEY,
  },
});

appInsights.loadAppInsights();
appInsights.trackPageView();

export default appInsights;
