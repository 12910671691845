import ApiUtils from "./../../utils/apiUtils";
import { useQuery } from "react-query";
import { message } from "antd";
import { getSoaId } from "../../utils/common";
import moment from "moment";

const downloadFile = (data, fileName, isUrl = false) => {
  const url = isUrl ? data : window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  link.setAttribute("target", "_blank");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const generateWordDocument = async ({ queryKey }) => {
  const { soaId } = queryKey[1];

  return ApiUtils.DOCUMENT_HTTPS.get(`/${soaId}/soa`, {
    responseType: "blob",
  });
};

const useGenerateWordDocument = () => {
  const soaId = getSoaId();

  const { data, isLoading, refetch } = useQuery(
    ["/generate-word", { soaId }],
    generateWordDocument,
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      onError: () => {
        message.error("Whoops - no luck! We'll look into this", 2.5);
      },
    }
  );

    const generateDoc = () => {
        const hide = message.loading("Generating Word Document", 0);

        refetch().then(res => {
            hide();
            if (res?.data) {
                message.success("Done");
                const adviceId = getSoaId();
                downloadFile(res.data.data, `${adviceId}.docx`);
            }
        });
    };

    return [generateDoc, {data, isLoading}];
};

const generateHarmonisedSoA = async ({soaId}) => {
    return ApiUtils.DOCUMENT_HTTPS.get(`/${soaId}/soa/harmonisation`, {
        responseType: "blob",
    });
};

const useGenerateHarmonisedSoA = () => {
    const soaId = getSoaId();

    const generateTemplate = async () => {
        let hide = message.loading("Generating Template", 0);
        try {
            let res = await generateHarmonisedSoA({soaId});
            if (res?.data) {
                message.success("Done");
                const adviceId = getSoaId();
                downloadFile(res.data, `${adviceId}-template.docx`);
            }
        } catch (error) {
            message.error("Whoops - no luck! We'll look into this", 2.5);
        } finally {
            hide();
        }
    };

    return [generateTemplate];
};

const generateFeeReport = async ({queryKey}) => {
    const {soaId} = queryKey[1];

    return ApiUtils.DOCUMENT_HTTPS.get(`/${soaId}/fee-comparison`, {
        responseType: "blob",
    });
};

const useGenerateFeeReport = () => {
    const soaId = getSoaId();

    const {data, isLoading, refetch} = useQuery(
        ["/generate-report", {soaId}],
        generateFeeReport,
        {
            enabled: false,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            retry: false,
            onError: () => {
                message.error("Whoops - no luck! We'll look into this", 2.5);
            },
        }
    );

    const generateReport = () => {
        const hide = message.loading("Generating Fee Report", 0);

        refetch().then(res => {
            hide();
            if (res?.data) {
                message.success("Done");
                const adviceId = getSoaId();
                downloadFile(res.data.data, `${adviceId}-report.zip`);
            }
        });
    };

    return [generateReport, {data, isLoading}];
};

const generateTranscript = async ({queryKey}) => {
    const {soaId} = queryKey[1];

    return ApiUtils.HTTPS.get(`/comment/${soaId}/transcript`, {
        responseType: "blob",
    });
};

const useGenerateTranscript = (soaId, name) => {
    const {data, isLoading, refetch} = useQuery(
        ["/generate-transcript", {soaId}],
        generateTranscript,
        {
            enabled: false,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            retry: false,
            onError: () => {
                message.error("Whoops - no luck! We'll look into this", 2.5);
            },
        }
    );

    const generate = () => {
        const hide = message.loading("Generating Transcript", 0);

        refetch().then(res => {
            hide();
            if (res?.data) {
                message.success("Done");
                let year = moment().format("YYYY");
                let month = moment().format("MM");
                downloadFile(
                    res.data.data,
                    `${year}${month} ${name.split(' ')[1]},${name.split(' ')[0].charAt(0)} - Planlogic Request Transcript.zip`
                );
            }

            });
          };

          return [generate, { data, isLoading }];
        };

        const getPoints = ({ queryKey }) => {
          const { soaId, key } = queryKey[1];

          return ApiUtils.DOCUMENT_HTTPS.get(
            `/${soaId}/${key}`).then(
            res => res.data.data
            );
    };

    const useGetPoints = (key, enabled) => {
        const soaId = getSoaId();

        const {data, error, isLoading, refetch, isFetching} = useQuery(
            [`/points`, {soaId, key}],
            getPoints,
            {
                onError: () => {
                    message.error("Something went wrong fetching the data..!!");
                },
                refetchOnWindowFocus: false,
                refetchOnMount: false,
                refetchOnReconnect: false,
                retry: false,
                enabled,
            }
        );

        return {data, error, isLoading, refetch, isFetching};
    };

    const getPortfolioProfile = ({queryKey}) => {
        let {soaId} = queryKey[1];

        return ApiUtils.DOCUMENT_HTTPS.get(`/${soaId}/portfolio`, {
            responseType: "blob",
        });
    };

    const useGetPortfolioProfile = () => {
        const soaId = getSoaId();

        const {data, isLoading, refetch} = useQuery(
            ["/portfolio-profile", {soaId}],
            getPortfolioProfile,
            {
                enabled: false,
                refetchOnWindowFocus: false,
                refetchOnMount: false,
                refetchOnReconnect: false,
                retry: false,
                onError: () => {
                    message.error("Whoops - no luck! We'll look into this", 2.5);
                },
            }
        );

        const generateProfile = () => {
            const hide = message.loading("Generating Word Document", 0);

            refetch().then(res => {
                hide();
                if (res?.data) {
                    message.success("Done");
                    const adviceId = getSoaId();
                    downloadFile(res.data.data, `${adviceId}-profile.docx`);
                }
            });
        };

        return [generateProfile, {data, isLoading}];
    };

    export {
        useGenerateWordDocument,
        useGenerateFeeReport,
        downloadFile,
        useGetPoints,
        useGenerateHarmonisedSoA,
        useGetPortfolioProfile,
        useGenerateTranscript,
    };
