import ApiUtils from "./../../utils/apiUtils";
import { useQuery } from "react-query";
import { message } from "antd";

import {
  useSetAutoCreateData,
  useSetFsoaStrategies,
  useSetAlerts,
} from "../../redux/hooks/recommendedStrategies";
import { useSetProjectionRequirements } from "../../redux/hooks/ancillary";

import { useInvestmentProducts as useProducts } from "../../redux/hooks/investmentTable";
import {
  useUpdatePracticeServices,
  useUpdateUiOptions,
} from "../../redux/hooks/basicDetails";
import { useGetSoaVersion, useSetInformation } from "../../redux/hooks/request";
import { useSetModalValues } from "../../redux/hooks/modals";
import { useContext } from "react";
import { AuthContext } from "../../Auth";

const getData = async ({ queryKey }) => {
  const [key] = queryKey;
  const config = {
    headers: {},
  };

  return ApiUtils.DSRF_HTTPS.get(`${key}`, config).then(res => res.data.data);
};

const getVersionData = async ({ queryKey }) => {
  const [key, variables] = queryKey;
  const config = {
    headers: {},
  };

  let versionKey = variables?.version ? `?version=${variables?.version}` : "";

  return ApiUtils.DSRF_HTTPS.get(`${key}${versionKey}`, config).then(
    res => res.data.data
  );
};

const useFrequestStrategies = () => {
  const setModalValues = useSetModalValues();
  const soaVersion = useGetSoaVersion();

  const { data, error, isFetching } = useQuery(
    ["data/frequent-strategies", { version: soaVersion }],
    getVersionData,
    {
      onError: () => {
        message.error(
          "Something went wrong fetching the Frequesnt Strategies..!!"
        );
      },
      onSuccess: data => {
        setModalValues("strategiesSearchDrawer", { frequentStrategies: data });
      },
      enabled: soaVersion !== undefined,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  );

  return { data, error, isFetching };
};

const useAutoCreateData = () => {
  const setAutoCreateData = useSetAutoCreateData();
  const soaVersion = useGetSoaVersion();

  const { data, error, isFetching } = useQuery(
    ["/data/autocreate", { version: soaVersion }],
    getVersionData,
    {
      onError: () => {
        message.error(
          "Something went wrong fetching the Auto Create Strategy data..!!"
        );
      },
      onSuccess: data => {
        setAutoCreateData(data);
      },
      enabled: soaVersion !== undefined,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  );

  return { data, error, isFetching };
};

const useFsoaStrategies = () => {
  const setFsoaStrategies = useSetFsoaStrategies();
  const soaVersion = useGetSoaVersion();

  const queryData = useQuery(
    ["/data/fsoa-strategies", { version: soaVersion }],
    getVersionData,
    {
      onError: () => {
        message.error(
          "Something went wrong fetching the FSoA Strategies data..!!"
        );
      },
      onSuccess: data => {
        setFsoaStrategies(data);
      },
      enabled: soaVersion !== undefined,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  );

  return queryData;
};

const useAlerts = () => {
  const setAlerts = useSetAlerts();
  const soaVersion = useGetSoaVersion();

  const queryData = useQuery(
    ["/data/alerts", { version: soaVersion }],
    getVersionData,
    {
      onError: () => {
        message.error("Something went wrong fetching the Alerts data..!!");
      },
      onSuccess: data => {
        setAlerts(data);
      },
      enabled: soaVersion !== undefined,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  );

  return queryData;
};

const useProjectionRequirements = () => {
  const setProjectionRequirements = useSetProjectionRequirements();

  const { data, error, isFetching } = useQuery(
    ["/data/projection-requirements"],
    getVersionData,
    {
      onError: () => {
        message.error(
          "Something went wrong fetching the Projection Requirements..!!"
        );
      },
      onSuccess: data => {
        setProjectionRequirements(data);
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
    }
  );

  return { data, error, isFetching };
};

const useInvestmentProducts = () => {
  const { setInvestmentProducts } = useProducts();
  const soaVersion = useGetSoaVersion();

  const { data, error, isFetching } = useQuery(
    ["/data/investment", { version: soaVersion }],
    getVersionData,
    {
      enabled: soaVersion !== undefined,
      onError: () => {
        message.error(
          "Something went wrong fetching the Investment Products..!!"
        );
      },
      onSuccess: data => {
        setInvestmentProducts({ products: data });
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  );

  return { data, error, isFetching };
};

const useUiOptions = () => {
  const update = useUpdateUiOptions();

  const { data, error, isFetching } = useQuery(
    ["/data/ui-options"],
    getVersionData,
    {
      onError: () => {
        message.error("Something went wrong fetching the UI Options..!!");
      },
      onSuccess: data => {
        update(data);
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  );

  return { data, error, isFetching };
};

const usePracticeServices = () => {
  const update = useUpdatePracticeServices();

  const { data, error, isFetching } = useQuery(
    ["/data/practice-services"],
    getVersionData,
    {
      onError: () => {
        message.error(
          "Something went wrong fetching the Practice Services..!!"
        );
      },
      onSuccess: data => {
        update(data);
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  );

  return { data, error, isFetching };
};

const useInformation = () => {
  const update = useSetInformation();

  const { data, error, isFetching } = useQuery(
    ["/data/information"],
    getVersionData,
    {
      onError: () => {
        message.error("Something went wrong fetching Information..!!");
      },
      onSuccess: data => {
        update(data);
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  );

  return { data, error, isFetching };
};

const useInternalReasons = () => {
  const setModalValues = useSetModalValues();

  const { data, error, isFetching } = useQuery(
    ["/data/internal-reasons"],
    getVersionData,
    {
      onError: e => {
        message.error("Something went wrong fetching Information..!!");
      },
      onSuccess: data => {
        setModalValues("holdDrawer", {
          ...data,
        });
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  );

  return { data, error, isFetching };
};

const useIntercomData = () => {
  const { parsedToken } = useContext(AuthContext);
  const { data, error, isFetching } = useQuery(["/data/intercom"], getData, {
    onError: () => {
      message.error("Something went wrong fetching Intercom Data..!!");
    },
    onSuccess: data => {
      if (window.Intercom) {
        const fullName =
          parsedToken?.given_name + " " + parsedToken?.family_name;
        const userId = parsedToken?.sub;
        const email = parsedToken?.email;

        // const hideLauncher = !data.includes(userId);

        window.Intercom("boot", {
          app_id: process.env.REACT_APP_INTERCOM_KEY,
          email: email,
          name: fullName,
          user_id: userId
        });
      }
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
  });

  return { data, error, isFetching };
};

export {
  useFrequestStrategies,
  useAutoCreateData,
  useFsoaStrategies,
  useAlerts,
  useProjectionRequirements,
  useInvestmentProducts,
  useUiOptions,
  usePracticeServices,
  useInformation,
  useInternalReasons,
  useIntercomData,
};
