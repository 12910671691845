import React from "react";

const AddCommentIcon = () => {
  return (
    <>
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Current-position"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Joint"
            transform="translate(-800.000000, -309.000000)"
            fill="#343A3F"
          >
            <g
              id="Actions-/-Operations-/-add-comment-/-24"
              transform="translate(800.000000, 309.000000)"
            >
              <path
                d="M13.305,22.5 L12,21.75 L15,16.5 L19.5,16.5 C20.3284271,16.5 21,15.8284271 21,15 L21,6 C21,5.17157288 20.3284271,4.5 19.5,4.5 L4.5,4.5 C3.67157288,4.5 3,5.17157288 3,6 L3,15 C3,15.8284271 3.67157288,16.5 4.5,16.5 L11.25,16.5 L11.25,18 L4.5,18 C2.84314575,18 1.5,16.6568542 1.5,15 L1.5,6 C1.5,4.34314575 2.84314575,3 4.5,3 L19.5,3 C21.1568542,3 22.5,4.34314575 22.5,6 L22.5,15 C22.5,16.6568542 21.1568542,18 19.5,18 L15.87,18 L13.305,22.5 Z M12.75,6.75 L11.25,6.75 L11.25,9.75 L8.25,9.75 L8.25,11.25 L11.25,11.25 L11.25,14.25 L12.75,14.25 L12.75,11.25 L15.75,11.25 L15.75,9.75 L12.75,9.75 L12.75,6.75 Z"
                id="Fill"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};

export default AddCommentIcon;
