import { createSelector } from "reselect";
import { REDUX_KEY } from "../../../utils/constants";

const reducerKey = REDUX_KEY.INVESTMENT;

export const getPlatforms = createSelector(
  state => state[reducerKey].platforms,
  platforms => platforms
);

export const getPlatform = idx =>
  createSelector(getPlatforms, platforms => platforms[idx]);

export const getPlatformValue = (idx, key) =>
  createSelector(getPlatforms, platforms => platforms[idx][key]);

export const getInvestmentProducts = createSelector(
  state => state[reducerKey].investmentProducts,
  products => products
);
