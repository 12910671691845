import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, Input, Row,message, Tooltip } from "antd";
import { SendOutlined, CloseOutlined, CloseCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { useGetModalValues } from "../../redux/hooks/modals";
import { shouldAlignRight } from "./CommentsList";
import { AuthContext } from "../../Auth";
import { useAddComment,useGetComments } from "../../api/currentPosition/comments";
import { useGetStatus } from "../../redux/hooks/request";
import { useDropzone } from "react-dropzone";
import { ACCEPTED_FILE_FORMATS } from "./utils";
import { COMMENT, REQUEST_STATUS } from "../../utils/constants";


const { TextArea } = Input;

const getReplyCaption = type => {
  switch (type) {
    case "approve":
      return "Provide approval comment";
    case "reject":
      return "Provide rejection comment";
    default:
      return "Replying to";
  }
};

const AddComment = 
  ({
    entityType,
    entityId,
    readOnly,
    inputRef,
    editComment,
    handleEditComment,
    parentId,
    replyComment,
    onCancelReplyComment,
    onCommentSubmit /* Not caring if success or failed */,
    resetThreadEditComment,
    label
  }) => {
    const { comments, open } = useGetModalValues("commentsDrawer");

    const [commentInput, setCommentInput] = useState(null);
    const requestState = useGetStatus();

    const key = '__root'
    const {
      refetch: fetchComments,
    } = useGetComments(entityType, entityId, key);

    const [addNewComment, { isLoading: isAddCommentPending }] = useAddComment();

    const [filesPreview, setFilesPreview] = useState([]);

    const handleAddComment = (comment, parentId) => {
      //Sending only files
      if (filesPreview && (comment === ("" || null))) {
        addNewComment({
          entityType,
          entityId,
          key,
          parentId,
          replyToId: replyComment?.commentId,
          requestState,
          files: filesPreview
        });
      }

      //Sending files along with the comment
      if ((filesPreview?.length !== 0) && (comment !== ("" || null))) {
        // parentId?'chat':entityType
      addNewComment({
          entityType: COMMENT.ENTITY_TYPE,
          entityId: COMMENT.ENTITY_ID,
          key,
          parentId,
          content: comment,
          replyToId: replyComment?.commentId,
          requestState,
          files: filesPreview
        });
      }

      //Sending only comment
      if (filesPreview.length == 0 && comment)
        if (comment !== ("" || null))
          addNewComment({
            entityType: COMMENT.ENTITY_TYPE,
            entityId: COMMENT.ENTITY_ID,
            key,
            parentId,
            content: comment,
            replyToId: replyComment?.commentId,
            requestState,
          });
      setFilesPreview([]);
      if (onCancelReplyComment) onCancelReplyComment()
    };
    const status = useGetStatus();

    const handlePreviewRemove = (index) => {
      const updatedFilesPreview = [...filesPreview];
      updatedFilesPreview.splice(index, 1);
      setFilesPreview(updatedFilesPreview);
    }

    useEffect(() => {
      if (!open) {
        setCommentInput(null);
      }
    }, [open]);

    useEffect(() => {
      if (editComment) {
        // const comment = comments.find(c => c.commentId === editComment);
        setCommentInput(editComment.content);
      }
    }, [comments, editComment]);

    const handleChange = event => {
      setCommentInput(event.target.value);
    };

    const handleSubmit = () => {
      if (onCommentSubmit) { onCommentSubmit() }

      if (commentInput === null && filesPreview.length === 0) {
        return;
      }
      if (editComment) {
        handleEditComment({ content: commentInput, commentId: editComment.commentId });
        resetThreadEditComment?.()
      } else {
        handleAddComment(commentInput, parentId);
      }
      setCommentInput(null);
    };

    const handleKeyDown = e => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        handleSubmit();
        setCommentInput(null);
      }
    };

    const handlePaste = e => {
      if (e.clipboardData.files.length === 0) {
        return;
      }
      const fileList = Array.from(e.clipboardData.files);
      setFilesPreview([...filesPreview, ...fileList]);
      // handleAddFiles(e.clipboardData.files);
    };

    const replyToComment = comments?.find(
      c => c.commentId === replyComment?.commentId
    );

    const {
      parsedToken: {
        sub,
        realm_access: { roles },
      },
    } = useContext(AuthContext);

    const disableUnreadComments =
      roles?.includes("advisor") && status === REQUEST_STATUS.inReview_reviewing;

    const { stakeholders } = useGetModalValues("commentsDrawer");

    const isParaplanner = roles && roles.includes("paraplanner");

    const isOwner = sub === replyToComment?.owner?.id;

    const alignRight = shouldAlignRight({
      stakeholders,
      ownerId: replyToComment?.owner?.id,
      userIsOwner: isOwner,
      userIsParaplanner: isParaplanner,
    });

    const showName = isParaplanner || alignRight;

    const name = showName ? replyToComment?.owner?.name : "PL Team";

    const onDrop = useCallback(
      acceptedFiles => {
        if (acceptedFiles.length > 0) {
          setFilesPreview([...filesPreview, ...acceptedFiles]);
        }
      },
      [addNewComment, entityId, entityType, key, filesPreview]
    );

    const onDropRejected = useCallback(fileRejections => {
      fileRejections.forEach(rejection => {
        message.error(
          `Could not upload ${rejection.file.name} - ${rejection.errors[0].message}`
        );
      });
    }, []);

    const {
      getRootProps,
      getInputProps,
      open: openFileDialog,
      isDragActive,
    } = useDropzone({
      onDrop,
      onDropRejected,
      noClick: true,
      noKeyboard: true,
      accept: ACCEPTED_FILE_FORMATS,
      disabled: readOnly,
    });

    return (
      <>
        <div
          {...getRootProps({
            className: `upload-container ${isDragActive ? "drag-active" : ""}`,
          })}
        >
          {/* Add file preview in the comment  */}
          <div className="preview">
            {
              filesPreview?.length > 0 &&
              filesPreview?.map((file, index) =>
                file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg" || file.type === "image/bmp" ?
                  <div className="preview-img-container">
                    <div style={{ border: '0.5px solid', borderColor: 'rgb(227, 227, 230)', borderRadius: '12px' }}>
                      <img crossOrigin="anonymous" loading="lazy" className="preview-img" src={URL.createObjectURL(file)} />
                    </div>
                    <CloseCircleOutlined
                      className="preview-img-cancel"
                      key={file}
                      onClick={() => handlePreviewRemove(index)} />
                  </div>
                  :
                  <div className="preview-file-container">
                    <CloseCircleOutlined
                      className="preview-file-cancel"
                      onClick={() => handlePreviewRemove(index)} />
                    <div>{file.name}</div>
                  </div>
              )
            }
          </div>
          
          <input
            {...getInputProps({
              className: "upload-input",
            })}
          />
{/* 
          {!replyToCommentId && (
            <Button
              className="upload-button"
              onClick={openFileDialog}
              icon={<UploadIcon />}
              disabled={readOnly || disableUnreadComments || editCommentId}
            >
              Click or Drag file to upload
            </Button>
          )} */}

          {replyComment && (
            <Row className="reply-to-comment">
              <div className="header">
                <p>{getReplyCaption(replyComment.reply_type)}</p>
                <CloseOutlined
                  onClick={onCancelReplyComment}
                />
              </div>
              <div className="comment">
                <p className="author">{replyComment.id}</p>
                <p className="content">{replyComment.content}</p>
              </div>
            </Row>
          )}


          <div className="input-section" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '4px' }}>
            <div style={{ flex: '1' }}>
              <TextArea
                ref={inputRef}
                disabled={readOnly}
                autoSize={{ minRows: 0, maxRows: 10 }}
                onChange={handleChange}
                value={commentInput}
                placeholder={label ? label:"Type your comment here"}
                onKeyDown={handleKeyDown}
                onPaste={handlePaste}
              />
            </div>
            <Tooltip title="send">
              {!replyComment &&
                <Button
                  disabled={readOnly}
                  onClick={openFileDialog}
                  icon={<UploadOutlined />}
                />}
            </Tooltip>

            <Tooltip title="send">
              <Button
                disabled={readOnly}
                onClick={handleSubmit}
                type="primary"
                icon={<SendOutlined />}
              />
            </Tooltip>
          </div>

            <Row>
              <small>
                <em>Enter</em>&nbsp; to send message, <em>Shift + Enter</em>&nbsp; for
                newline.
              </small>
            </Row>
        </div>
        {/* <div
          className={`drop-message ${isDragActive ? "drag-active" : ""}`}
        >
          <CommentsUploadIcon />
        </div> */}
          {/* <p>Drop files to instantly upload them</p> */}
      </>
    );
  }

export default AddComment;
